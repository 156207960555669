import React, { useEffect,useState} from "react";
import { Form,Grid } from "semantic-ui-react";
import { useFormik } from "formik";
import { Convocatoria,Course } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { initialValues, validationSchema } from "./ConvocatoriaForm.form";
import "./ConvocatoriaForm.scss";
const postController = new Convocatoria();
const courseController = new Course();

export function ConvocatoriaForm({ onClose, onReload,convocatoria }) {
  // console.log(convocatoria)

  const { accessToken } = useAuth();
  const [courses, setCourses] = useState(null)
  const [valueCourses,setValueCourses] = useState(null)
  useEffect(() => {
    (async () => {
      try {
        setCourses(null);
        const response = await courseController.getAllcourses(accessToken);
        setCourses(response);        
      } catch (error) {
        console.error(error);
      }
    })();    
  }, [accessToken]);

  useEffect(() => {
    if(courses!=null){
      setValueCourses(courses?.map((course,index)=>{
        return {
          key: index+1,
          text: course?.title,
          value: course?.title,
          // price: course?.price
        };
      }))      
    }  
  }, [courses])
  
  const formik = useFormik({
    initialValues: initialValues(convocatoria),   
    validationSchema: validationSchema(convocatoria),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {            
        if (convocatoria) {
          await postController.updateConvocatoria(accessToken, convocatoria._id, formValue);
        } else {
          await postController.createConvocatoria(accessToken, formValue);
        }
        onReload();
        onClose();
      } catch (error) {
        console.error(error);
      }
    },
  });
  return (
    <Form className="post-form" onSubmit={formik.handleSubmit}>
      {/* <Form.Group widths="equal"> */}
      <Form.Group widths='equal'>
        <Form.Input
          label="Entidad solicitante"
          name="title"
          placeholder="Lugar de requerimiento"
          onChange={formik.handleChange}
          value={formik.values.title}
          error={formik.errors.title}
        />
         <Form.Input
          label="Profesión para el puesto"
          name="position"
          placeholder="Cargo"
          onChange={formik.handleChange}
          value={formik.values.position}
          error={formik.errors.position}
        />
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Input
          type="date"
          label="Fecha limite de postulación"
          name="date"     
          onChange={formik.handleChange}
          value={formik.values.date}
          error={formik.errors.date}
        />
        <Form.Input
          type="time"
          label="Hora limite"
          name="hour"    
          onChange={formik.handleChange}
          value={formik.values.hour}
          error={formik.errors.hour}
        />
        <Form.Input
          label="Sueldo"
          type="number"
          name="pay"
          placeholder="Sueldo"
          onChange={formik.handleChange}
          value={formik.values.pay}
          error={formik.errors.pay}
        />
        <Form.Input
          label="Ubicación"
          name="location"
          placeholder="Ubicación"
          onChange={formik.handleChange}
          value={formik.values.location}
          error={formik.errors.location}
        />
      </Form.Group>
      <Form.Group widths='two'>
        <Form.Group >          
        <Grid columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Form.Field>
              <label>Requisitos</label>
              <Form.Checkbox
                label='Cv u Hoja de vida'
                name="cv"
                checked={formik.values.cv}                
                onChange={(e, data) => formik.setFieldValue('cv', data.checked)}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
             <br/>
              <Form.Checkbox
                label='Formularios A1 A2 C1 C2'
                name="forms"
                checked={formik.values.forms}
                onChange={(e, data) => formik.setFieldValue('forms', data.checked)}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
        </Form.Group>
        <Form.Dropdown
          search
          fluid
          label="Cursos solicitados"
          placeholder="Selecciona un curso"
          options={valueCourses}
          selection
          multiple
          onChange={(e, data) => formik.setFieldValue('courses', data.value)}
          value={Array.isArray(formik.values.courses) ? formik.values.courses : []}
          error={formik.errors.courses}
        />
      </Form.Group>
      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        {convocatoria ? "Actualizar convocatoria" : "Crear convocatoria"}
      </Form.Button>
    </Form>
  );
}

