import React from "react";
import { Menu, Icon,Dropdown } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../../hooks";
import "./AdminMenu.scss";


export function AdminMenu() {
  const { pathname } = useLocation();
  const {
    user: { role },
  } = useAuth();
  const isAdmin = role === "admin";

  const isCurrentPath = (path) => {
    if (path === pathname) return true;
    return false;
  };

  return (
    <Menu fluid vertical icon text className="admin-menu">
      
        <>
        {isAdmin && (
          <Menu.Item
            as={Link}
            to="/admin/users"
            active={isCurrentPath("/admin/users")}
          >           
            <Icon name="user outline" />
            Usuario
          </Menu.Item>
        )}
          <Menu.Item
            as={Link}
            to="/admin/clients"
            active={isCurrentPath("/admin/clients")}
          >
            <Icon name="address card" />
            Clientes
          </Menu.Item>

          <Menu.Item
            as={Link}
            to="/admin/envios"
            active={isCurrentPath("/admin/envios")}
          >
               <Icon name="cart" />
            Envios
          </Menu.Item>

          <Menu.Item
            as={Link}
            to="/admin/courses"
            active={isCurrentPath("/admin/courses")}
          >
                 <Icon name="student" />
        
            Cursos
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/admin/convocatorias"
            active={isCurrentPath("/admin/convocatorias")}
          >
            <Icon name="edit" />
            Convocatorias
          </Menu.Item>   
        </>
        {isAdmin && (
          <>
            {/* <Menu.Item
          as={Link}
          to="/admin/reportes"
          active={isCurrentPath("/admin/reportes")}
        >
          <Icon name="clipboard outline" />
          Reportes
        </Menu.Item> */}
        <Dropdown
          item
          trigger={<span style={{ display: 'flex', width: '100%' }}><span><Icon name='clipboard outline' /> Reportes</span></span>}
        >
          <Dropdown.Menu>
            <Dropdown.Item text="Por fechas" as={Link} to="/admin/reportefecha" />
            <Dropdown.Item text="Por agente" as={Link}  to="/admin/reportevendedor" />
            <Dropdown.Item text="Por cliente" as={Link} to="/admin/reportecliente" />
            <Dropdown.Item text="Por Curso" as={Link} to="/admin/reportecurso" />
          </Dropdown.Menu>
        </Dropdown>
          </>
        )}
    </Menu>
  );
}
