import { ENV } from "../utils";

export class Client {
  baseApi = ENV.BASE_API;

  async createClient(accessToken,seller, clientData) {
    try {      
      const data={...clientData,seller}
      const url = `${ENV.BASE_API}/${ENV.API_ROUTES.CLIENT}`;
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-type':'application/json'
        },
        body:JSON.stringify(data)
      };
      const response = await fetch(url, params);
      const result = await response.json();

      if(response.status ===200) {
       return(result)
      }
      else{throw result}     
    } catch (error) {
      throw error;
    }
  }

  async getClients(accessToken) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.CLIENTS}`;
      const params = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async updateClient(accessToken, idClient, clientData) {
    try {
      const data = clientData;     
      const url = `${ENV.BASE_API}/${ENV.API_ROUTES.CLIENT}/${idClient}`;
      const params = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-type':'application/json'
        },
        body:JSON.stringify(data)
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async deleteClient(accessToken, idClient) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.CLIENT}/${idClient}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }



}



