import React from "react";
import { Routes, Route } from "react-router-dom";
import { AdminLayout } from "../layouts";
import { Auth, Users,Clients, Courses, Convocatoria,Report,Sends } from "../pages/admin";
import { useAuth } from "../hooks";

export function AdminRouter() {
  const { user } = useAuth();

  const loadLayout = (Layout, Page) => {
    return (
      <Layout>
        <Page />
      </Layout>
    );
  };

  return (
    <Routes>
      {!user ? (
        <Route path="/admin/*" element={<Auth />} />
      ) : (
        <>
          <Route path="/admin" element={loadLayout(AdminLayout, Clients)}/>
          <Route path="/admin/users" element={loadLayout(AdminLayout, Users)}/>
          <Route path="/admin/clients" element={loadLayout(AdminLayout, Clients)}/>
          <Route path="/admin/courses"element={loadLayout(AdminLayout, Courses)}/>
          <Route path={"/admin/convocatorias"} element={loadLayout(AdminLayout, Convocatoria)}/> 
          <Route path="/admin/envios" element={loadLayout(AdminLayout, Sends)}/> 

          <Route path="/admin/reportefecha" element={loadLayout(AdminLayout, Report)}/>
          <Route path="/admin/reportevendedor" element={loadLayout(AdminLayout, Report)}/>
          <Route path="/admin/reportecliente" element={loadLayout(AdminLayout, Report)}/>
          <Route path="/admin/reportecurso" element={loadLayout(AdminLayout, Report)}/>
        </>
      )}
    </Routes>
  );
}


