import React from "react";
import { ListSends} from "../../../components/Admin/Send";
import "./Sends.scss";

export function Sends() {
  return (
      <div>         
        <div className="send_list">
           <ListSends   />      
        </div>    
      </div>
   
  );
}


