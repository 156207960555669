// const SERVER_IP = "localhost:3977";
//const SERVER_IP = "192.95.21.32:3977";
const SERVER_IP = "https://risingconsultora.com:4000";
// const SERVER_IP = "https://rising-back-carlas-projects-d9dabf56.vercel.app"
export const ENV = {
  //local
//  BASE_PATH: `https://${SERVER_IP}`,
 // BASE_API: `https://${SERVER_IP}`,
  //paramontar
   BASE_PATH: SERVER_IP,
   BASE_API: SERVER_IP,
  API_ROUTES: {
    REGISTER: "api/auth/register",
    LOGIN: "api/auth/login",
    REFRESH_ACCESS_TOKEN: "api/auth/refresh_access_token",
    USER_ME: "api/user/me",
    USER: "api/user",
    TRANSACTION: "api/transaction",
    USERS: "api/users",
    CLIENT: "api/client",
    CLIENTS: "api/clients",
    COURSE: "api/course",
    COURSES: "api/courses",
    MENU: "api/menu",
    NEWSLETTER: "api/newsletter",
    CONVOCATORIA: "api/convocatoria",
  },
  JWT: {
    ACCESS: "access",
    REFRESH: "refresh",
  },

  URL:`${SERVER_IP}/api/course`,
};
