import * as Yup from "yup";

export function initialValues(transction) {
  return {
    courses: transction?.courses || "",        
    price: transction?.price || "", 
  };
}

export function validationSchema() {
  return Yup.object({
    courses: Yup.string().required(true),  
    price: Yup.number().required(true), 
  });
}
