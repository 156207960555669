import React ,{useState} from "react";
import { Image, Button ,Modal ,ModalContent} from "semantic-ui-react";
import { ENV } from "../../../../utils";
import "./Course.scss";

export function Course({ course }) {  
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const openImageModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };
  // console.log(course)
  const curso=course.title.replace(/ /g, "+");
  const whas=`https://api.whatsapp.com/send?phone=59167658313&text=Hola+quisieras+mas+informacion+del+curso+${curso}` 
  return (
    <div className="coursesitem">
      {/* <Image  className="coursesitem_image" src={`${ENV.BASE_PATH}/${course.miniature}`} />       */}
      <Image
          className="coursesitem_image"             
          src={`${ENV.BASE_PATH}/${course.miniature}`}
          onClick={() =>
            openImageModal(
              `${ENV.BASE_PATH}/${course.miniature}`
            )
          }                      
      />
     
      <div className="courseitem__info">
          <h3 className="coursesitem__info__title">{course.title}</h3>
          <h3 className="coursesitem__info__workload">Carga horaria: {course.workload} horas academicas</h3>
          <h3 className="coursesitem__info__price">Precio: {course.price}Bs</h3>
          <Button as="a" href={whas} primary fluid target="_blank"  className="coursesitem__info__button">
              SOLICITAR CURSO
          </Button>
         
        </div>
    
        
        
    <Modal open={openModal} onClose={closeModal} closeIcon className="imagen_modal" basic>
      <ModalContent closeIcon>
          <Image   src={selectedImage}  style={{ width: '100%', height: '100%' }}/>
       </ModalContent>
    </Modal>

    
    </div>
  );
}
