import React from 'react'
import { Form } from "semantic-ui-react";
import { FormData} from "./index";
export const UpdateClientForm = ({formik,user,gradeOptions,cityOptions, handleAddCity,handleAddGrade}) => {
 

  return (
       <Form className="user-form" onSubmit={formik.handleSubmit}>    
          <FormData  formik={formik} user={user} gradeOptions={gradeOptions} cityOptions={cityOptions} handleAddCity={handleAddCity} handleAddGrade={handleAddGrade} />
          <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
          Actualizar usuario
          </Form.Button>       
      </Form>
  )
}








// return(
//     <Form className="user-form" onSubmit={formik.handleSubmit}>    
//     <Form.Input 
//       fluid label="Nombre"
//       name="name"
//       placeholder="Nombre"
//       onChange={formik.handleChange}
//       value={formik.values.name}
//       error={formik.errors.name}
//     />
//       <Form.Input 
//       fluid label="Carnet"
//       name="ci"
//       placeholder="Carnet"
//       onChange={formik.handleChange}
//       value={formik.values.ci}
//       error={formik.errors.ci}
//     />
//     <Form.Input
//       fluid label="Celular"
//       name="phone"
//       placeholder="Celular"
//       onChange={formik.handleChange}
//       value={formik.values.phone}
//       error={formik.errors.phone}
//     />     

//     <Form.Dropdown
//       search
//       fluid label="Grado academico"
//       name="grade"
//       placeholder="Grado academico"
//       options={gradeOptions}
//       selection
//       onChange={(_, data) => formik.setFieldValue("grade", data.value)}
//       value={formik.values.grade}
//       error={formik.errors.grade}
//     />    
//     <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
//       {user ? "Actualizar usuario" : "Crear cliente"}
//     </Form.Button>
//   </Form>
// )
