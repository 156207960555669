import React from "react";
import { Container } from "semantic-ui-react";
import { ListConvocatorias } from "../../../components/Web/Convocatorias";
import "./Convocatorias.scss"
export function Convocatorias() {
  return (
    <div className="convocatorias-background">
      <Container className="convocatorias-background__content">
        <ListConvocatorias />
      </Container>
    </div>
  
  );
}
