import React from "react";
import { Container } from "semantic-ui-react";
import "./Banner.scss";

export function Banner() {
  return (
    <div className="banner">
      <Container>
        <h1 >
          CAPACITATE AHORA PARA           
        </h1>
        <h1 className="banner-t2">
        TRIUNFAR MAÑANA
        </h1>
        <h2 className="banner-sub">
        Queremos que nuestros cursos sean una  experiencia positiva y enriquecedora para todo el que decida formar parte de nuestra comunidad educativa.
        </h2>
        
      </Container>

      {/* <div className="banner__dark" /> */}
    </div>
  );
}
