import React from 'react'
import {  Button, Loader} from "semantic-ui-react";
import{Link} from "react-router-dom"
import "./ValidateQr.scss";
export const ValidateQrMessage = ({curso}) => {
        if(curso===null){
        return(
            <div className="validate">
                <div className='validate-loader'>
                    <Loader active inline='centered' size='massive' >
                        Cargando...
                    </Loader>
                </div>
            </div>
        )
    }
    else if(curso===undefined){
        return (
            <div className="validate">           
                <h1 className="validate-title">El codigo no es valido</h1>
                <h2 className="validate-failed">Puede ingresar el codigo manualmente</h2>
                <div className="validate-button">
                    <Link to="/validacion" className="link">  
                        <Button inverted>
                            <h3>Buscar por codigo  </h3>  
                        </Button>   
                    </Link>
          
                </div>
          </div>
        )

    }
    else{


        return(
            <div className="valid">
            <h1 className="valid-title">Verificación exitosa</h1>
            <h2 className="valid-message__min"> Felicidades{curso?.grade === "Sin grado academico" ? "" : curso?.grade} {curso?.clientname} su certificado es totalmente válido y ha sido verificado correctamente.</h2>
            {/* <div className="valid-message__course">
              <h2 className="valid-message__course__name">ID: </h2>   
              <h2 className="valid-message__course__chil">{curso?.id}</h2>   
            </div>   */}
            <div className="valid-message__course">
              <h2 className="valid-message__course__name">CURSO ADQUIRIDO: <span  className="valid-message__course__chil">{curso?.course}</span></h2>
            </div>
            <div className="valid-message__course">
              <h2 className="valid-message__course__name">CODIGO: <span  className="valid-message__course__chil">{curso?.id}</span></h2>
            </div>
          </div>
        )

    }

  

  
}
