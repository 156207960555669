import { image } from "./../assets"
  
export const socialData = [
  {
    name:"Teléfono",
    type: "whatsapp",
    value:"67658313",
    link: "https://wa.me/+59167658313?text=Hola%20,quisieras%20mas%20informacion",
    color:"green",
    image:image.whatsapp,
  },
  {
    name:"Facebook",
    type: "facebook",
    value:"consultora rising",
    link: "https://www.facebook.com/profile.php?id=61550936205392",
    color:"blue",
    image:image.facebook,
  },
  {
    name:"E-mail",
    type: "mail outline",
    value:"risingconsultora@gmail.com",
    // link: "https://risingconsultora@gmail.com ",
    color:"grey",
    image:image.email,
  },
  {
    name:"Instagram",
    type: "instagram",
    value:"consultora rising",
    link: "https://www.instagram.com/consultorarising/",
    color: "purple",
    image:image.instagram,
  },
  

  
];
