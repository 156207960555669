import React, { useState, useEffect } from "react";
import { Container } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import { TopBar, Footer } from "../../components/Web";
import "./ClientLayout.scss";

export function ClientLayout(props) {
  const { children } = props;
  // const [showImage, setShowImage] = useState(true); // Comenzar con 'true'
  // const location = useLocation();
  // const pageClass = location.pathname.substring(1);

  
  return (
    <div>
    
      <div>
        <TopBar />
      </div>

      <div >
       { children}
      </div>

      <div className="footer">
        <Container className="footer_container">
          <Footer.Social />
        </Container>
        <Container>
          <span>© ALL RIGHTS RESERVED</span>
          <span></span>
        </Container>
      </div>
    </div>
  );
}