import React from "react";
import { Button, Input } from "semantic-ui-react";
import "../ValidateCertificate.scss";
export function ValidateInput({handleInputChange,handleButtonClick}) {  
 
  return (
    <>
      <div className="validation">
        <h1 className="validation-title">VALIDACION DE CERTIFICADOS</h1>
        <div className="validation-content">
          <h2 className="validation-message">Para mayor confiabilidad por medio del sistema usted o cualquier institución podrá verificar la autenticidad del certificado correspondiente.</h2>
          <h2 className="validation-message-2">Para ello ingrese el ID del certificado en la parte inferior.</h2>

        </div>
        
        <div className="validation-space">
        <Input className="validation-space__input" 
          placeholder='Ingrese ID del certificado '  
          transparent fluid 
          style={{ color: 'red' }}
          onChange={handleInputChange}
          action={            
            <Button
              icon= 'search'
              onClick={handleButtonClick}                     
            />              
          }
        />
        </div>
      </div>  
    </>
  
  )
}
