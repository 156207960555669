import * as Yup from "yup";

export function inititalValues() {
  return {
    username: "",
    password: "",
  };
}

export function validationSchema() {
  return Yup.object({
    username: Yup.string()
      // .email("El nombre de usuario no es valido")
      .required("Campo obligatorio"),
    password: Yup.string().required("Campo obligatorio"),
  });
}
