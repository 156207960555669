import { ReactComponent as LogoRising } from "./svg/rising-logo.svg";
import { ReactComponent as LogoWhite } from "./svg/rising-white.svg";
import authBg from "./jpg/auth-bg.jpg";
import homeBanner from "./jpg/home-banner.jpg";

import academyLogo from "./png/academy-logo.png";
import vision from "./us/vision.png"
import mision from "./us/mision.png"
import valores from "./us/valores.png"
import whatsapp from "./social/whatsapp.png"
import email from "./social/email.png"
import facebook from "./social/facebook.png"
import instagram from "./social/instagram.png"
import risingWhite from "./svg/rising-white.svg";
const Icon = {
  LogoRising,
  LogoWhite
};
const image = {
  authBg,
  homeBanner,
  academyLogo,
 
  mision,
  valores,
  vision,
  whatsapp,
  email,
  facebook,
  instagram,
  risingWhite
};

export { Icon, image };
