import React, { useState } from "react";
import {  Button, Icon, Confirm,Table } from "semantic-ui-react";
import { Client } from "../../../../api"; 
import { useAuth } from "../../../../hooks";
import { BasicModal } from "../../../Shared";
import { ClientForm } from "../ClientForm";
import { BuyCourse } from "../Course/BuyCourse/BuyCourse";
import { ViewCourses } from "../Course/ViewCourses/ViewCourses";

import "./ClientItem.scss";
const userController = new Client();


export function ClientItem(props) {
  // console.log(props)
  const {client, onReload,index } = props;
  const { accessToken } = useAuth();

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);


  const openUpdateUser = () => {
    setTitleModal(`Actualizar ${client.name}`);
    onOpenCloseModal();
    setModalContent(
    <ClientForm close={onOpenCloseModal} onReload={onReload} user={client}  />);
  };

  const openBuyCourse = () => {
    setTitleModal(`Comprar:  ${client.name}`);
    onOpenCloseModal();
    setModalContent(
    <BuyCourse close={onOpenCloseModal} onReload={onReload} client={client}  />);
  };
  const openViewCourses = () => {
    setTitleModal(`Cursos de: ${client.name}`);
    onOpenCloseModal();
    setModalContent(
    <ViewCourses client={client} close={onOpenCloseModal} onReload={onReload}/>);
  };


  const onActivateDesactivate = async () => {
    try {
      await userController.updateClient(accessToken, client._id, {
        active: !client.active,
      });
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  const openDeleteConfirm = () => {
    setIsDelete(true);
    setConfirmMessage(`Eliminar al cliente: ${client.name}`);
    onOpenCloseConfirm();
  };

  const onDelete = async () => {
    try {
      await userController.deleteClient(accessToken, client._id);
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };


  return(
    <>

      <Table.Row key={client._id}>
        <Table.Cell>{index}</Table.Cell>
        <Table.Cell>{client.name}</Table.Cell>
        <Table.Cell>{client.ci}</Table.Cell>
        <Table.Cell>{client.phone}</Table.Cell>
        <Table.Cell>{client.grade}</Table.Cell>
        <Table.Cell  textAlign='center'>
          <Button  icon color="blue" onClick={() => openBuyCourse()}>
            <Icon name="cart plus" />
          </Button>
          <Button  icon color='green' onClick={() => openViewCourses()} >
            <Icon name="eye" />
          </Button>
        </Table.Cell>
        <Table.Cell  textAlign='center'>
          <Button  icon color="blue" onClick={() => openUpdateUser()}>
            <Icon name="pencil" />
          </Button> 
          <Button  icon color="red" onClick={() => openDeleteConfirm()}>
            <Icon name="trash" />
          </Button>            
        </Table.Cell>
      </Table.Row>

      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal} size="large">
        {/* <ClientForm close={onOpenCloseModal} onReload={onReload} user={client} /> */}
        {modalContent}
      </BasicModal>

      {/* <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
        <ClientForm close={onOpenCloseModal} onReload={onReload} user={client} />
      </BasicModal> */}

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={isDelete ? onDelete : onActivateDesactivate}
        content={confirmMessage}
        size="mini"
      />

    </>   
    
  )
}

