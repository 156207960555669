import React,{useEffect,useState}from "react";
import { Transaction } from "../../../api";
import { ValidateQrMessage } from "./ValidateQrMessage";
const TransactionController = new Transaction();

export function ValidateQr({path}) { 
  console.log(path)
  const [curso, setCurso] = useState(null)
  useEffect(() => {
    (async () => {      
     try {
    
       const response = await TransactionController.getVerificateTransactions(path);
       setCurso(response);
     } catch (error) {
      //  console.error(error);
       setCurso(null);
     }
     })(path); 
   }, []);
 
  return (
    
      <ValidateQrMessage curso={curso}/>  
    
    
  )
}
