import React, { useState, useEffect } from "react";
import { Loader, Pagination,Table,Input } from "semantic-ui-react";
import { size, map } from "lodash";
import { Course } from "../../../../api";
import { CourseItem } from "../CourseItem";
import "./ListCourses.scss";
const courseController = new Course();
export function ListCourses(props) {
  const { reload, onReload } = props;
  const [courses, setCourses] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCourses, setFilteredCourses] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const response = await courseController.getCourses( page,5000 );
        setCourses(response.docs);
        setPagination({
          limit: response.limit,
          page: response.page,
          pages: response.pages,
          total: response.total,
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [page, reload]);

  const changePage = (_, data) => {
    setPage(data.activePage);
  };

  useEffect(() => {
    if (courses) {
      const filtered = courses.filter(convocatoria => {
        for (const key in convocatoria) {
          if (typeof convocatoria[key] === 'string' &&
              convocatoria[key].toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
      setFilteredCourses(filtered);
    }
  }, [searchTerm, courses]);

  if (!courses) return <Loader active inline="centered" />;
  if (size(courses) === 0) return "No hay ningun curso";

  return (
    <div className="list-courses">

        <Input
          placeholder="Buscar por título..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
 
      <Table celled compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: '40%' }}>Nombre del curso</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }}>Precio</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }}>Carga Horaria</Table.HeaderCell>            
            <Table.HeaderCell style={{ width: '20%' }}>Acciones</Table.HeaderCell>            
          </Table.Row>
        </Table.Header>
        <Table.Body>
            {map(filteredCourses, (course) => (
        <CourseItem key={course._id} course={course} onReload={onReload} />
      ))}
        </Table.Body>
      </Table>
    

      <div className="list-courses__pagination">
        <Pagination
          totalPages={pagination.pages}
          defaultActivePage={pagination.page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={changePage}
        />
      </div>
    </div>
  );
}
