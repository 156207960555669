import * as Yup from "yup";

export function initialValues(convocatoria) {
  return {
    title: convocatoria?.title || "",
    position: convocatoria?.position || "",
    date: convocatoria?.date || "",
    hour:convocatoria?.hour || "",
    pay: convocatoria?.pay || "",
    location: convocatoria?.location || "",
    cv: convocatoria?.cv || false,
    forms: convocatoria?.forms || false,   
    courses: convocatoria?.courses || [],

  };
}

export function validationSchema() {
  return Yup.object({
    title: Yup.string().required('El campo no puede esta vacio'),
    position: Yup.string().required('El campo no puede esta vacio'),
    location: Yup.string().required('El campo no puede esta vacio'),
    date: Yup.string().required('El campo no puede esta vacio'),
    pay: Yup.number().required('El campo no puede esta vacio'),
    courses: Yup.array().required(true),
  });
}
// position:String,
  // requirements:String,
  // date:date,
  // pay:Number,