import * as Yup from "yup";

export function initialValues(user) {
  return {
    name: user?.name || "",
    username: user?.username || "",   
    role: user?.role || "",
    password: "",
  };
}

export function validationSchema(user) {
  return Yup.object({
    name: Yup.string().required(true),
    username: Yup.string().required(true),   
    role: Yup.string().required(true),
    password: user ? Yup.string() : Yup.string().required(true),
  });
}
