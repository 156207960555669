import React,{useEffect, useState}from "react";
import { Message } from "semantic-ui-react";
import { useFormik } from "formik";
import { Client } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { initialValues, validationSchema } from "./ClientForm.form";
import { UpdateClientForm,NewClientFrom} from "./Forms";
import {cityOptionsValues,gradeOptionsValues } from "../../../../utils"
import "./ClientForm.scss";

const clientController = new Client();

export function ClientForm( { close, onReload, user }) {
  const { accessToken } = useAuth();
  const seller= useAuth().user._id;
  const [cityOptions, setCityOpctions] = useState(cityOptionsValues)
  const [gradeOptions, setGradeOpctions] = useState(gradeOptionsValues)
  const [error, setError] = useState(null);

  useEffect(() => {
    if(user){
      const valueCity ={key: user?.city,text: user?.city,value: user?.city};
      setCityOpctions([...cityOptions, valueCity])
      const valueGrade ={key: user?.grade,text: user?.grade,value: user?.grade};
      setGradeOpctions([...gradeOptions, valueGrade])
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddCity = (e, { value }) => { 
    const newOption ={key: value,text: value,value: value,};
    setCityOpctions([...cityOptions, newOption]);
  };
  
  const handleAddGrade = (e, { value }) => { 
    const newOption ={key: value,text: value,value: value,};
    setGradeOpctions([...gradeOptions, newOption]);
  };

  const formik = useFormik({
    initialValues: initialValues(user),
    validationSchema: validationSchema(user),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (!user) {
          await clientController.createClient(accessToken,seller, formValue);       
        } else {
          await clientController.updateClient(accessToken, user._id, formValue);
        
        }
        onReload();
        close();
      } catch (error) {     
        console.log('ew')  
        console.log(error)
        setError(error.msg);
      }
    },
  });
  // console.log(formik.values)
  return (
    <>
    {user? 
      <UpdateClientForm formik={formik}  gradeOptions={gradeOptions} cityOptions={cityOptions} handleAddCity={handleAddCity} handleAddGrade={handleAddGrade}/>
    :
      <NewClientFrom formik={formik}  gradeOptions={gradeOptions} cityOptions={cityOptions} handleAddCity={handleAddCity} handleAddGrade={handleAddGrade}/>
      
    }
    {error && (
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{error}</p>
        </Message>
      )}
    
    </>
  );
}
