export const gradeOptionsValues = [
    {key: "Sin",text: "Sin grado academico",value: "Sin grado academico"},
    {key: "Lic.",text: "Lic.",value: "Lic."},
    {key: "Ing.",text: "Ing.",value: "Ing."},
    {key: "Arq.",text: "Arq.",value: "Arq."},
    {key: "Abg.",text: "Abg.",value: "Abg."},
    {key: "Dr.",text: "Dr.",value: "Dr."},
    {key: "Dra.",text: "Dra.",value: "Dra."},
    {key: "Abog.",text: "Abog.",value: "Abog."},
    {key: "Sr.",text: "Sr.",value: "Sr."},
    {key: "Sra.",text: "Sra.",value: "Sra."},
    {key: "Stria.",text: "Stria.",value: "Stria."},
    {key: "Aux.",text: "Aux.",value: "Aux."},
    {key: "Aux. Enf.",text: "Aux. Enf.",value: "Aux. Enf."},
    {key: "Tec.",text: "Tec.",value: "Tec."},
    {key: "Tec. Med.",text: "Tec. Med.",value: "Tec. Med."},
    {key: "Tec. Sup.",text: "Tec. Sup.",value: "Tec. Sup."},
    {key: "Msc. Lic.",text: "Msc. Lic.",value: "Msc. Lic."},
    {key: "Msc.",text: "Msc.",value: "Msc."},
    {key: "Phd. Lic.",text: "Phd. Lic.",value: "Phd. Lic."},
    {key: "Phd.",text: "Phd.",value: "Phd."},
];

export const cityOptionsValues=[
    {key: "potosi", text: "Potosi", value: "Potosi",},
    {key: "lapaz",  text: "La Paz", value: "La Paz",},
    {key: "cochabamba",text: "Cochabamba",value: "Cochabamba", },
    {key: "sucre",text: "Sucre",value: "Sucre",},
    {key: "oruro",text: "Oruro",value: "Oruro",},
    {key: "tarija",text: "Tarija",value: "Tarija",},
    {key: "trinidad",text: "Trinidad",value: "Trinidad",},
    {key: "santa cruz",text: "Santa Cruz",value: "Santa Cruz",},

  ]