import { ENV } from "../utils";

export class Course {
  baseApi = ENV.BASE_API;

  async createCourse(accessToken, data) {       
    try {      
      const url = `${this.baseApi}/${ENV.API_ROUTES.COURSE}`;
      const formData = new FormData();    
      Object.keys(data).forEach((key) => {
       return formData.append(key, data[key]);
      });
  
      console.log(formData)
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
      
        },
        body:formData
      };
      const response = await fetch(url, params);
      const result = await response.json();
      if (response.status !== 201) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  // async getCourses() {
  //   try {     
  //     const url = `${this.baseApi}/${ENV.API_ROUTES.COURSE}`;
  //     console.log(url)
  //     const response = await fetch(url); 
  //     const result = await response.json();
  //     if (response.status !== 200) throw result;

  //     return result;
  //   } catch (error) {      
  //     throw error;
  //   }
  // }
  
  async getCourses(page = 1, limit = 10) {
    try {
      const pageFilter = `page=${page}`;
      const limitFilter = `limit=${limit}`;
      const url = `${this.baseApi}/${ENV.API_ROUTES.COURSES}?${pageFilter}&${limitFilter}`;  
      const response = await fetch(url);
      const result = await response.json();
      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async getAllcourses() {
    try {
      const url = `${this.baseApi}/api/allcourses`;         
      const response = await fetch(url);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }


  // allcourses

  async updateCourse(accessToken, idCourse, data) {
    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      // if (data.file) {
      //   formData.append("miniature", data.file);
      // }

      const url = `${ENV.BASE_API}/${ENV.API_ROUTES.COURSE}/${idCourse}`;
      const params = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async deleteCourse(accessToken, idCourse) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.COURSE}/${idCourse}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }
}
