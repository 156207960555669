import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { BasicModal } from "../../../components/Shared";
import { ClientForm,ListClients } from "../../../components/Admin/Clients";
import "./Clients.scss";


export function Clients() {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onReload = () => setReload((prevState) => !prevState);

  return (
    <>
      <div className="users-page">
        <Button className="users-page__add" primary onClick={onOpenCloseModal}>
          Nuevo cliente
        </Button>    
        <div className="client_list">
          <ListClients  reload={reload} onReload={onReload} />       
        </div>    
      </div>

      <BasicModal
        show={showModal}
        close={onOpenCloseModal}
        title="Crear nuevo cliente"
        size="large"
      >    
          <ClientForm close={onOpenCloseModal} onReload={onReload} />     
        
      </BasicModal>
    </>
  );
}
