import React from "react";
import { Link } from "react-router-dom";
import "./ListConvocatoriasItem.scss";
import { Button } from "semantic-ui-react";
export function ListConvocatoriasItem({ convocatoria }) {
 
  return (
      <div className="list-post">
        <Link className="list-post-item" to={`/convocatorias/${convocatoria.path}`}>
            <h2 className="list-post-item__title">{convocatoria.title}</h2>     
            <div className="list-post-item__container">
              <h2 className="list-post-item__container__cargo">{convocatoria.position}</h2>
              {/* {convocatoria.forms?
               <h2 className="list-post-item__container__otros">DEBE LLENAR LOS FORMULARIOS A1, A2, CI, C2</h2>
               :
               <></>
              }
              {convocatoria.cv?
                <h2 className="list-post-item__container__otros">DEBE PRESENTAR Cv u HOJA DE VIDA</h2>
               :
               <></>
              }           
              <h2 className="list-post-item__container__otros">{convocatoria.requirements}</h2>
              <h2 className="list-post-item__container__otros">Fecha{convocatoria.hour?' y Hora':''} limite de postulacion</h2>
              <h2 className="list-post-item__container__otros">{convocatoria.date.split('-').reverse().join('-')}{convocatoria.hour?` a horas ${convocatoria.hour}`:''}</h2>
              <h2 className="list-post-item__container__otros">{convocatoria.pay?`Sueldo: ${convocatoria.pay}Bs`:''}</h2> */}
              <div >
                <Button>
                  Ver requisitos
                </Button>
              </div>
              {/* <h2 className="list-post-item__container__otros">Cursos requeridos:</h2>
              {convocatoria.courses.map((curso,index)=>{
                return(<h2 key={index} className="list-post-item__container__otros">{curso}</h2>)
              })} */}
             
          </div>
          
        </Link>
    </div>
  );
}
