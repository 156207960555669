import React, { useState } from "react";
import { Image, Button, Icon, Confirm,Table} from "semantic-ui-react";
import { BasicModal } from "../../../Shared";
import { ENV } from "../../../../utils";
import { Course } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { CourseForm } from "../CourseForm";
import "./CourseItem.scss";

const courseController = new Course();

export function CourseItem(props) {
  const { course, onReload } = props;
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const { accessToken } = useAuth();

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const openUpdateCourse = () => {
    setTitleModal(`Actualizar ${course.title}`);
    onOpenCloseModal();
  };

  const onDelete = async () => {
    try {
      await courseController.deleteCourse(accessToken, course._id);
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>

      <Table.Row key={course._id}>       
        <Table.Cell>{course.title}</Table.Cell>
        <Table.Cell>{course.price} Bs</Table.Cell>
        <Table.Cell>{course.workload} horas</Table.Cell>
        <Table.Cell  textAlign='center'>
        
          <Button icon primary onClick={openUpdateCourse}>
            <Icon name="pencil" />
          </Button>
          <Button icon color="red" onClick={onOpenCloseConfirm}>
            <Icon name="trash" />
          </Button>
        </Table.Cell>        
      </Table.Row>
   

      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}  size="large">
        <CourseForm
          onClose={onOpenCloseModal}
          onReload={onReload}
          course={course}
        />
      </BasicModal>

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={onDelete}
        content={`Eliminar el curso ${course.title}`}
        size="mini"
      />
    </>
  );
}
