import React from "react";
import { useParams } from "react-router-dom";
import "./Convocatoria.scss";
import { ItemComvocatoria } from "../../../components/Web/Convocatoria/ItemComvocatoria";


export function Convocatoria() { 
  const { path } = useParams();
  return(
    <div className="convocatoria-background">
       <ItemComvocatoria path={path}/>
    </div>
   
  )
}
