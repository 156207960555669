import React, { useState } from "react";
import { Button, Icon, Confirm,Table } from "semantic-ui-react";
import { Convocatoria } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { BasicModal } from "../../../Shared";
import { ConvocatoriaForm } from "../ConvocatoriaForm";
import "./ConvocatoriaItem.scss";

const convocatoriaController = new Convocatoria();


export function ConvocatoriaItem({convocatoria,onReload}){
  const [showModal, setShowModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const { accessToken } = useAuth();

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const onDelete = async () => {
    try {
      await convocatoriaController.deleteConvocatoria(accessToken, convocatoria?._id);
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Table.Row key={convocatoria?._id}>                  
         
          <Table.Cell>{convocatoria?.title}</Table.Cell>
          <Table.Cell>{convocatoria?.position}</Table.Cell>     
          <Requisitos cv={convocatoria?.cv} form={convocatoria?.forms} />    
          <Table.Cell>{convocatoria?.date.split('-').reverse().join('-')}{convocatoria.hour?` Hora ${convocatoria.hour}`:''}</Table.Cell>
          <Table.Cell>{convocatoria?.pay}</Table.Cell>
          <Table.Cell  textAlign='center'>       
            <Button icon primary onClick={onOpenCloseModal}>
              <Icon name="pencil" />
            </Button>
            <Button icon color="red" onClick={onOpenCloseConfirm}>
              <Icon name="trash" />
            </Button>
          </Table.Cell>        
        </Table.Row>
      <BasicModal
        show={showModal}
        close={onOpenCloseModal}
        title="Editar convocatoria"
        size="large"
      >
        <ConvocatoriaForm onClose={onOpenCloseModal} onReload={onReload} convocatoria={convocatoria} />
      </BasicModal>
      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={onDelete}
        content={`¿Eliminar ${convocatoria?.title}?`}
        size="mini"
      />
    </>
  );
}

const Requisitos=({cv, form})=>{
  if(cv===true && form===true){
    return(
      <Table.Cell>Cv u hoja de vida, Formularios(A1, A2, C1, C2)</Table.Cell>
    )
  }
  if(cv===true){
   return(
    <Table.Cell>Cv u hoja de vida</Table.Cell>
   )
  }
  if(form===true){
    return(
      <Table.Cell>Formularios(A1, A2, C1, C2)</Table.Cell>
    )

  }
  else
  return(
  <Table.Cell>Ninguno</Table.Cell>
)
  
}