import React from 'react'
import { Form } from "semantic-ui-react";
export const FormData = ({formik,gradeOptions,cityOptions,handleAddCity,handleAddGrade}) => {

 
  return (
    <>
        <Form.Group widths={2}>
        <Form.Input 
            fluid label="Nombre"
            name="name"
            placeholder="Nombre"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.errors.name}
        />
        <Form.Group widths={2}>
            <Form.Input 
            fluid label="Carnet"
            name="ci"
            placeholder="Carnet"
            onChange={formik.handleChange}
            value={formik.values.ci}
            error={formik.errors.ci}
            />
            <Form.Input
            fluid label="Celular"
            name="phone"
            placeholder="Celular"
            onChange={formik.handleChange}
            value={formik.values.phone}
            error={formik.errors.phone}
            />
        </Form.Group>
    </Form.Group>
        
    <Form.Group widths={2}>
        {/* <Form.Dropdown
          search
          fluid label="Grado academico"
          name="grade"
          placeholder="Grado academico"
          options={gradeOptions}
          selection
          onChange={(_, data) => formik.setFieldValue("grade", data.value)}
          value={formik.values.grade}
          error={formik.errors.grade}
        />     */}
        <Form.Dropdown
          search          
          allowAdditions
          name="grade"
          fluid label="Grado academico"
          placeholder="Grado academico"
          options={gradeOptions}
          selection
          onChange={(_, data) => formik.setFieldValue("grade", data.value)}
          value={formik.values.grade}
          error={formik.errors.grade}
          onAddItem={handleAddGrade}   
        />
        <Form.Dropdown
          search          
          allowAdditions
          name="city"
          fluid label="Ciudad"
          placeholder="Ciudad"
          options={cityOptions}
          selection
          onChange={(_, data) => formik.setFieldValue("city", data.value)}
          value={formik.values.city}
          error={formik.errors.city}
          onAddItem={handleAddCity}   
        />
    </Form.Group>
    </>
    
  )
}
