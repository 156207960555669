import React, { useState, useEffect } from "react";
import { Loader, Pagination,Table,Input } from "semantic-ui-react";
import { map, size } from "lodash";
import { Convocatoria } from "../../../../api";
import { ConvocatoriaItem } from "../ConvocatoriaItem";
import "./ListPost.scss";

const convocatoriaController = new Convocatoria();

export function ListConvocatoria(props) {
  const { reload, onReload } = props;
  const [convocatorias, setConvocatorias] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(1);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredConvocatorias, setFilteredConvocatorias] = useState(null);


  useEffect(() => {
    (async () => {
      try {
        const response = await convocatoriaController.getConvocatorias(page);
        setConvocatorias(response.docs);
        setPagination({
          limit: response.limit,
          page: response.page,
          pages: response.pages,
          total: response.total,
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [page, reload]);

  const changePage = (_, data) => {
    setPage(data.activePage);
  };

  useEffect(() => {
    // Filtrar cursos por título cuando el término de búsqueda cambia
    if (convocatorias) {
      const filtered = convocatorias.filter(convocatoria => {
        // Filtrar por cualquier dato que contenga el término de búsqueda
        for (const key in convocatoria) {
          if (typeof convocatoria[key] === 'string' &&
              convocatoria[key].toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
      setFilteredConvocatorias(filtered);
    }
  }, [searchTerm, convocatorias]);
  


  if (!convocatorias) return <Loader active inline="centered" />;
  if (size(convocatorias) === 0) return "No hay convocatorias";

  return (
    <div >
      <div className="convocatoria-in">
      <Input          
          placeholder="Buscar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      
      <div className="list-post__table">
      <Table celled compact>
        <Table.Header>
          <Table.Row>        
            <Table.HeaderCell style={{ width: '20%' }}>Entidad</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '20%' }}>Cargo</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }}>Requerimientos</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }}>Fecha</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }}>Sueldo</Table.HeaderCell>   
            <Table.HeaderCell style={{ width: '15%' }}>Acciones</Table.HeaderCell>             
          </Table.Row>
        </Table.Header>
        <Table.Body>
            {map(filteredConvocatorias, (convocatoria) => (
        <ConvocatoriaItem key={convocatoria._id} convocatoria={convocatoria} onReload={onReload} />
      ))}
        </Table.Body>
      </Table>
      </div>
     
      {/* {map(posts, (post) => (
        <PostItem key={post._id} post={post} onReload={onReload} />
      ))} */}

      <div className="list-post__pagination">
        <Pagination
          totalPages={pagination.pages}
          defaultActivePage={pagination.page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={changePage}
        />
      </div>
    </div>
  );
}
