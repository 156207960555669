import React from "react";
import { Banner, HomeCourses} from "../../../components/Web";
import"./home.scss";
export function Home() {

  return (
    <div className="home-banner">      
        <Banner />
        <HomeCourses />    
    </div>
  );
}
