import { ENV } from "../utils";
export class Transaction {
  baseApi = ENV.BASE_API;

  async createTransaction(accessToken,seller,clientid,data) {
    const datos={...data,seller,clientid}
    try {      
      const url = `${this.baseApi}/${ENV.API_ROUTES.TRANSACTION}`;
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-type':'application/json'
        },
        body:JSON.stringify(datos)
      };
      const response = await fetch(url, params);
      const result = await response.json();
      if (response.status !== 201) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getTransactions(accessToken,id) {
    try {
      const url = `${this.baseApi}/api/transaction/${id}`;
      const params = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(url, params);
      const result = await response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      // throw error;
    }
  }

  async getVerificateTransactions(transactionid) {
    try {
      const url = `${this.baseApi}/api/transactionverificate/${transactionid}`;  
      const response = await fetch(url);
      console.log(response)
      const result = await response.json();
      if (response.status !== 200) {throw result};
      return result;
    } catch (error) {
    }
  }
 
  async deleteTransaction(accessToken, idTransaction) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.TRANSACTION}/${idTransaction}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(url, params);
      const result = await response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }
  async getTransactionsSend(accessToken) {
    try {
      const url = `${this.baseApi}/api/transactionsend`;
      const params = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(url, params);
      const result = await response.json();
      return result;
    } catch (error) {
    }
  }

  async transactionUpdate(accessToken, idClient) {
    try {    
      const url = `${ENV.BASE_API}/api/transactionupdate/${idClient}`;
      const params = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-type':'application/json'
        },
        body:JSON.stringify()
      };
      const response = await fetch(url, params);
      const result = await response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }
}


