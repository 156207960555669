import React, { useState, useEffect } from "react";
import { Container, Loader,Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Convocatoria as ConvocatoriaAPi} from "../../../api"
import "./ItemComvocatoria.scss"
const convocatoriaController = new ConvocatoriaAPi();
export const ItemComvocatoria = ({path}) => {
  const [convocatoria, setConvocatoria] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await convocatoriaController.getConvocatoria(path);
        setConvocatoria(response);
      } catch (error) {
        setConvocatoria([])
      }
    })();
  }, [path]);
  console.log(convocatoria)
  if (!convocatoria) return ( <Loader active inline="centered" />);
  if (convocatoria.length===0) return ( 
    <Container>
      <div className="err404">
        <h2 className="err404_title">404</h2>
        <h2 className="err404_title">No encontrado</h2>
      </div>
   
    </Container>
  );
  return (
    <div>

      <Container>       
      <div className="convocatoria-content">    
        <h1 className="convocatoria__title">         
          {convocatoria.title}   
        </h1>
        <div className="convocatoria-table">
          <div className="convocatoria-table-tr" >
            <h2 className="convocatoria-table-tr__title" >CARGO</h2>       
            <div  className="convocatoria-table-tr__content">     
            <h2 className="convocatoria-table-tr__content"> {convocatoria.position}</h2>
            </div>
          </div>
          
          <div className="convocatoria-table-tr">
              <h2 className="convocatoria-table-tr__title">REQUISITOS</h2>             
             <div  className="convocatoria-table-tr__content">
             {convocatoria.forms?
              <h3  className="convocatoria-table-tr__content" > FORMULARIOS A1, A2, CI, C2 </h3>
               :            
               <>  </>
              }
              {convocatoria.cv?
                 <h3 className="convocatoria-table-tr__content"> HOJA DE VIDA O CV</h3>
                :
                <></>
               } 
         
             </div>
          </div>   

          <div className="convocatoria-table-tr" >
            <h2 className="convocatoria-table-tr__title" >FECHA Y HORA</h2>       
            <div  className="convocatoria-table-tr__content">     
            <h2 className="convocatoria-table-tr__content"> {convocatoria.date.split('-').reverse().join('-')}{convocatoria.hour?` a horas ${convocatoria.hour}`:''}</h2>
            </div>
          </div>

          <div className="convocatoria-table-tr" >
            <h2 className="convocatoria-table-tr__title" >SUELDO</h2>       
            <div  className="convocatoria-table-tr__content">     
            <h2 className="convocatoria-table-tr__content">{convocatoria.pay}</h2>
            </div>
          </div>

          <div className="convocatoria-table-tr" >
            <h2 className="convocatoria-table-tr__title" >CURSOS REQUERIDOS</h2>       
            <div  className="convocatoria-table-tr__content">     
            {convocatoria.courses.map((curso,index)=>{
                return(<h2  key={index}className="convocatoria-table-tr__content" >{curso} </h2>)
              })}
            </div>
          </div>
          </div>
          <div  className="convocatoria-table__button">
          <Link to={`/convocatorias`}>
            <Button>
              Ver otras convocatorias
            </Button>
          </Link>
            </div>
      </div>      
      </Container>
 
    </div>
   
  );
}
