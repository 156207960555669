import React from "react";
import { Container } from "semantic-ui-react";
import { Validate} from "../../../components/Web";
import "./ValidateCertificate.scss"
export function ValidateCertificate() {
  return (
    <div className="container__validation">
      <Container >
        <Validate/>  
      </Container>     
    </div>
  );
}
