import { ENV } from "../utils";

export class Report {
baseApi = ENV.BASE_API;
// async getClientsReport(accessToken,urlLoadData) {
async getDataReport(accessToken,urlLoadData) {
    try {
      // const url = `${this.baseApi}/clientsforreport`;
      const url = `${this.baseApi}/${urlLoadData}`;
      const params = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  // async getTransactionsClient(accessToken,id,urlDataTable) {
  async getDataTable(accessToken,id,urlDataTable) {
    try {
      // const url = `${this.baseApi}/clientTransactions/${id}`;
       const url = `${this.baseApi}/${urlDataTable}/${id}`;
      const params = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(url, params);
      const result = await response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      // throw error;
    }
  }

  async getDataDate(accessToken,data) {
    console.log(data)
    const {dateStart,dateEnd}=data
    try {   
      //  const url = `${this.baseApi}/datetransactions/${dateStart}/${dateEnd}`;
      const url = `${this.baseApi}/api/datetransactions?dateStart=${dateStart}&dateEnd=${dateEnd}`;
      const params = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(url, params);
      const result = await response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      // throw error;
    }
  }

}
