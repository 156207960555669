import {useState,useEffect} from 'react'
import {Report } from "../api"
export const useReport = (accessToken,urlLoadData,urlDataTable) => {
  const reportController = new Report();
  const [selectData, setSelectData] = useState([])
  const [generateReport, setgenerateReport] = useState(false)
  const [changeValue, setChangeValue] = useState(false)
  const [tableData, setTableData] = useState([])
  const [selectedData, setSelectedData] = useState('');
  const [dataName, setDataName] = useState('')
  const [load, setLoad] = useState(true)
  useEffect(() => {
    if(urlLoadData!==''){
      (async () => {
        try {  
          const response = await reportController.getDataReport(accessToken,urlLoadData);
          setSelectData(response);
        } catch (error) {
          console.error(error);
        }
      })();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  
  
  useEffect(() => {
    setgenerateReport(false)   
      if(changeValue===true){
        (async () => {
          setChangeValue(false)
          setTableData([])
          setLoad(false)
          try { 
              const response = await reportController.getDataTable(accessToken,selectedData,urlDataTable);
              setTableData(response);
              setLoad(true)
          } catch (error) {
            console.error(error);
          }
        })();
      }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateReport]); 

  const handleviewTable=()=>{
    setgenerateReport(true)
    const selectedClientsObjects = selectData.find(client => selectedData.includes(client.value)
    );
    setDataName(selectedClientsObjects) 
  }

  const handleChange = (e, { value }) => {
    setChangeValue(true)
    setSelectedData(value);
  
  };
  return {
    selectData,
    selectedData,
    handleChange,
    tableData,
    handleviewTable,
    load,
    setgenerateReport,
    dataName

  }
}
