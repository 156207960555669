import React, { useState, useEffect } from "react";
import { Container, Button,Input,Loader } from "semantic-ui-react";
import { map } from "lodash";
import { Course as CourseController } from "../../../api";
// import { image } from "../../../assets";
import { Course } from "../../../components/Web/Courses";
import "./Courses.scss";

const courseController = new CourseController();
export function Courses() {
  const [courses, setCourses] = useState(null);
  const [filteredCourses, setFilteredCourses] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [load, setLoad] = useState(false)
  const isCurrentLastPage = pagination?.page === pagination?.pages;

  useEffect(() => {  
      (async () => {
        setLoad(false)
        try {
      
          const response = await courseController.getCourses( page, 33 );
          setPagination({
            page: response.page,
            pages: response.pages,
          });
          setLoad(true)
          if (!courses) setCourses(response.docs);
          else setCourses([...courses, ...response.docs]);
        } catch (error) {
          console.error(error);
        }
      })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    // Filtrar cursos por título cuando el término de búsqueda cambia
    if (courses) {
      const filtered = courses.filter(course =>
        course.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCourses(filtered);
    }
  }, [searchTerm, courses]);

  const loadMore = () => {
    setPage((prevState) => prevState + 1);
  };

  return (
    <div className="background_courses"> 
      <Container className="courses-page">
      <h2>Todos nuestros cursos</h2>
      <hr className="courses-page__line"/>
   
     {load===true?
     <>
        <div className="courses-page__input">
        <Input
          className="courses-page__input__2"
          icon="search"
          size="large"
          placeholder="Buscar por título..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
       <div className="courses">
     {map(filteredCourses || courses, (course) => (
       <div key={course._id} className="courses__item">
         <Course course={course} />
       </div>
     ))}
   </div>
   {!isCurrentLastPage && (
     <div className="more">
       <Button primary onClick={loadMore}>
         Cargar más...
       </Button>
     </div>
   )}
     </>
   :
   <div className="loader" >
      <Loader active inline="centered" size='massive'/>
   </div>
     }
    </Container>
    </div>
  );
}