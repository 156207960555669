import React, { useState } from "react";
import {  Button,Confirm } from "semantic-ui-react";
import { BasicModal } from "../../../components/Shared";
import { ListConvocatoria,ConvocatoriaForm } from "../../../components/Admin/Convocatoria";
import { useAuth } from "../../../hooks";
import { Convocatoria as Conv } from "../../../api";
import "./Convocatoria.scss";

const convocatoriaController = new Conv();
export function Convocatoria() {
  const { accessToken } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onReload = () => setReload((prevState) => !prevState);



  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");

  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);


  const openDeleteConfirm = () => {  
    setConfirmMessage(`Eliminar convocatorias pasadas?`);
    onOpenCloseConfirm();
  };

  const onDelete = async () => {
    const fecha = new Date().toISOString().split('T')[0];
    try {
      await convocatoriaController.deletePrevConvocatorias(accessToken,fecha);
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="convocatoria-page">
        <div className="convocatoria-page__add">  
          <div>
            <Button color='red' onClick={openDeleteConfirm}>
              Eliminar convocatorias
            </Button>
          </div> 
          <div>
            <Button primary onClick={onOpenCloseModal}>
              Nueva convocatoria
            </Button>
          </div>      
 
        </div>
        
        
        <ListConvocatoria reload={reload} onReload={onReload} />
      </div>
      <BasicModal
        show={showModal}
        close={onOpenCloseModal}
        title="Crear nueva convocatoria"
        size="large"
      >
        <ConvocatoriaForm onClose={onOpenCloseModal} onReload={onReload} />
      </BasicModal>

      <Confirm
         open={showConfirm}
         onCancel={onOpenCloseConfirm}
         onConfirm={onDelete}
         content={confirmMessage}
         size="mini"
       />
    </>
  );
}
