import React, { useState, useEffect } from "react";
import { Container, Image, Button, Loader,Modal ,ModalContent } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Course } from "../../../api"
import { ENV } from "../../../utils";
import "./HomeCourses.scss";

const courseController = new Course();

export function HomeCourses() {
  const [courses, setCourses] = useState(null);
  const [load, setload] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);


  useEffect(() => {  
      (async () => {
        setload(false)
        try {          
          const response = await courseController.getCourses(1,6);
          setCourses(response.docs);         
          setload(true)   
        } catch (error) {
          console.error(error);
        }
      })();
  }, []);


  const openImageModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <Container>
      <div className="home-courses"> 
      <h2 className="home-courses__title">Cursos Recientes</h2>
      <hr className="home-courses__line"/>
    
    {load===true?
    <>
      <div className="course">
        {courses?.map(course=>{         
          const curso=course.title.replace(/ /g, "+");
          const whas=`https://api.whatsapp.com/send?phone=59167658313&text=Hola+quisieras+mas+informacion+del+curso+${curso}`       
          return(
            <div className="course__item" key={course._id} >
            <div className="course__item-cont">

            {/* <Image className="course__item-cont-image" src={`${ENV.BASE_PATH}/${course.miniature}`} />    */}
              <Image
                  className="course__item-cont-image"
                  src={`${ENV.BASE_PATH}/${course.miniature}`}
                  onClick={() =>
                     openImageModal(
                      `${ENV.BASE_PATH}/${course.miniature}`
                     )
                   }                      
              />
            </div>            
             <div className="course__item__info">            

               <h3 className="course__item__info__title">{course.title}</h3>
               <h3 className="course__item__info__workload">Carga horaria: {course.workload===undefined?"":`${course.workload} horas academicas`} </h3>
               <h3 className="course__item__info__price">Precio: {course.price}Bs</h3>
               <Button as="a" href={whas} primary fluid target="_blank">
                  SOLICITAR CURSO
               </Button> 
             </div>
           </div>
          )
        })
        }
      </div>
      <div className="home-courses__more">
      <Link to="/cursos" className="link">             
        <Button  primary onClick={scrollToTop}>
            Ver más
        </Button>   
      </Link>    
        
      </div>
    </>
    :
    <div className="loader" >
      <Loader active inline="centered" size='massive'/>
    </div>
    }

      

    <Modal open={openModal} onClose={closeModal} closeIcon className="imagen_modal" basic  >
      <ModalContent closeIcon>
          <Image   src={selectedImage}  style={{ width: '100%', height: '100%' }}/>
       </ModalContent>
    </Modal>
    </div>  
    
    </Container>
  );
}
