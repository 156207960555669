import React from "react";
import {  Button ,Loader} from "semantic-ui-react";
import "../ValidateCertificate.scss";

export function ValidateMessage({validate,setLoad,loading}) { 
  const handleButtonClick =()=>{
    setLoad(true)
  }
  return (
    <>
      {loading===false?
      <div className="validation-content">
        <Loader active inline='centered' size='massive' >
                       
        </Loader>
      </div>
      :
      <div className="validation">
      <div >      
        {validate? 
        <div className="validation-content">
          <h1 className="validation-title">Verificación exitosa</h1>
          <h2 className="validation-message__min"> Felicidades{validate?.grade === "Sin grado academico" ? "" : validate?.grade} {validate?.clientname} su certificado es totalmente válido y ha sido verificado correctamente.</h2>
          {/* <div className="validation-message__course">
            <h2 className="validation-message__course__name">ID: </h2>   
            <h2 className="validation-message__course__chil">{validate?.id}</h2>   
          </div>   */}
          <div className="validation-message__course">
            <h2 className="validation-message__course__name">CURSO ADQUIRIDO: <span className="validation-message__course__chil">{validate?.course}</span></h2>
          </div>

          <div className="validation-message__course">
            <h2 className="validation-message__course__name">CODIGO: <span className="validation-message__course__chil">{validate?.id}</span></h2>
          </div>
        </div>
      :
      <div>
         <div className="validation-content">           
            <h1 className="validation-title">El codigo ingresado no es valido{validate?.course}</h1>
            {/* <h2 className="validation-message">Para mayor confiabilidad por medio del sistema usted o cualquier institución podrá verificar la autenticidad del certificado correspondiente.</h2>
            <h2 className="validation-message">Para ello ingrese el ID del certificado en la parte inferior.</h2> */}
            <div className="validation-button">
            <Button
                inverted        
                onClick={handleButtonClick}                     
            >
              <h3>Buscar otro codigo  </h3>  
            </Button>   
            </div>
          </div>

      </div>
         
        
          
        }
      </div>
    </div>  
      }

     
    </>
  
  )
}
