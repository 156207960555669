import React, { useState } from "react";
import {  Button, Icon, Confirm,Table } from "semantic-ui-react";
import { User } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { BasicModal } from "../../../Shared";
import { UserForm } from "../UserForm";
import "./UserItem.scss";

const userController = new User();

export function UserItem(props) {
  const { user, onReload } = props;
  const { accessToken } = useAuth();
 
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [isDelete, setIsDelete] = useState(false);

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const openUpdateUser = () => {
    setTitleModal(`Actualizar ${user.name}`);
    onOpenCloseModal();
  };

  const openDesactivateActivateConfim = () => {
    setIsDelete(false);
    setConfirmMessage(
      user.active
        ? `Desactivar usuario ${user.name}`
        : `Activar usuario ${user.name}`
    );
    onOpenCloseConfirm();
  };

  const onActivateDesactivate = async () => {
    try {
      await userController.updateUser(accessToken, user._id, {
        active: !user.active,
      });
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  const openDeleteConfirm = () => {
    setIsDelete(true);
    setConfirmMessage(`Eliminar usuario ${user.name}`);
    onOpenCloseConfirm();
  };

  const onDelete = async () => {
    try {
      await userController.deleteUser(accessToken, user._id);
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  return(
    <>        
            <Table.Row key={user._id}>
              <Table.Cell>{user.name}</Table.Cell>
              <Table.Cell>{user.username}</Table.Cell>
              <Table.Cell>{user.role}</Table.Cell>
              <Table.Cell textAlign='center' >
                <Button  icon color="blue"  onClick={openUpdateUser}>
                  <Icon name="pencil"/>
                </Button>
                <Button
                  icon
                  color={user.active ? "orange" : "green"}
                  onClick={openDesactivateActivateConfim}
                >
                  <Icon name={user.active ? "ban" : "check"} />
                </Button>
                <Button icon color="red" onClick={openDeleteConfirm}>
                  <Icon name="trash" />
                </Button>
              </Table.Cell>
            </Table.Row>
    <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
         <UserForm close={onOpenCloseModal} onReload={onReload} user={user} />
       </BasicModal>

       <Confirm
         open={showConfirm}
         onCancel={onOpenCloseConfirm}
         onConfirm={isDelete ? onDelete : onActivateDesactivate}
         content={confirmMessage}
         size="mini"
       />
    </>
  )

}
