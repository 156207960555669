import React,{useState,useEffect} from "react";
import { Transaction } from "../../../../api";

import { ValidateInput } from "../ValidateInput";
import { ValidateMessage } from "../ValidateMessage";
import "../ValidateCertificate.scss";

const TransactionController = new Transaction();

export function Validate() {  
  const [inputValue, setInputValue] = useState('');
  const [validate, setValidate] = useState(null)
  const [load, setLoad] = useState(true)
  const [reload, setReload] = useState(true)  
  const [loading, setLoading] = useState(false)
  const handleInputChange = (e, { value }) => { 
    setInputValue(value);
    
  };

  const handleButtonClick =()=>{
    if(inputValue!==''){
      setLoad(false)
      setReload(!reload)
    }   
  }
  useEffect(() => {
   if(load===false)
   {   (async () => {  
    setLoading(false)    
    try {
      setValidate(null);
      const response = await TransactionController.getVerificateTransactions(inputValue);
      setValidate(response);
      setLoading(true)   
    } catch (error) {
      console.log('err')
    }
    })(); 
  }
    setInputValue('')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <>       
    {load ? 
         <ValidateInput 
           handleInputChange={handleInputChange}
           inputValue={inputValue}
           validate={validate}
           handleButtonClick={handleButtonClick}
           />
      :
       <ValidateMessage  
        validate={validate}
        setLoad={setLoad}
        loading={loading}
        />
       }
    </>
    
    
    
   
    
 
  
  )
}
