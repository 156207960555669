import React, { useState, useEffect } from "react";
import { Loader, Input, Table, Pagination } from "semantic-ui-react";
import { size, map } from "lodash";
import { User } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { UserItem } from "../UserItem";
import "./listuser.scss";

const userController = new User();
export function ListUsers(props) {
  const { usersActive, reload, onReload } = props;
  const { accessToken } = useAuth();
  const [users, setUsers] = useState(null);
  const [filtrarPor, setFiltrarPor] = useState('');
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  useEffect(() => {
    (async () => {
      try {
        setUsers(null);
        const response = await userController.getUsers(accessToken,usersActive);
        setUsers(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [usersActive, reload]);

  if (!users) return <Loader active inline="centered" />;
  if (size(users) === 0) return "No hay ningún usuario";
  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage);
  };
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredUsers = users.filter((dato) => {
    const valores = Object.values(dato);
    return valores.some((valor) =>
      typeof valor === "string" && valor.toLowerCase().includes(filtrarPor.toLowerCase())
    );
  });
  const paginatedUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <div>
      <Input className="listuser_input"
        placeholder="Buscar"
        value={filtrarPor}
        onChange={(e) => setFiltrarPor(e.target.value)}
      />
      <Table celled compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: '30%' }}>Nombre</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '30%' }}>Usuario</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '10%' }}>Rol</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '20%' }}>Acciones</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {map(paginatedUsers, (user) => (
            <UserItem key={user._id} user={user} onReload={onReload} />
          ))}
        </Table.Body>
      </Table>
      <Pagination
        activePage={activePage}
        onPageChange={handlePaginationChange}
        totalPages={Math.ceil(filteredUsers.length / itemsPerPage)}
      />
    </div>
  );
}
