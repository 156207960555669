import React from "react";
import { Segment,Grid,Image } from "semantic-ui-react";
import { socialData } from "../../../../utils";
import "./Social.scss";

export function Social() {
  return (
    <div className="footer">
      <h1 className="footer-title">COMUNICATE CON NOSOTROS</h1>
      <h2 className="footer-sub">Para mayor información</h2>
      <div className="footer-social">
        <Segment basic>
          <Grid columns={2} stackable textAlign='center'>       
            <Grid.Row  className="footer-social-segment__row">
              {socialData?.map((social,index)=>{
                  return(
                    <Grid.Column key={index} className="footer-social-segment">
                    <div className="footer-social-segment__value">
                      <a href={social?.link}   target="_blank"  rel="noreferrer" className="footer-social-segment__value__link" >
                      <div className="footer-social-segment__value__content">    
                        <div className="footer-social-segment__value__content__image">
                          <Image src={social.image}/>
                        </div>                  
                      </div>
                      <div className="footer-social-segment__value__content">
                        <div className="footer-social-segment__value__content__title">{social.name} <br/></div>
                        <div className="footer-social-segment__value__content__value">{social.value}</div>
                       
                      </div>
                      </a>
                    </div>
                  </Grid.Column>
                  )
              })
              }
            </Grid.Row>
          </Grid>
        </Segment>
      </div>

    </div>
  );
}
