import React, { useState, useEffect } from "react";
import { Loader,Input,Button} from "semantic-ui-react";
import { useSearchParams } from "react-router-dom";
import { Convocatoria } from "../../../../api";
import { ListConvocatoriasItem } from "../ListConvocatoriasItem";
import "./ListConvocatorias.scss";

const convocatoriaController = new Convocatoria();
export function ListConvocatorias() {
  const [convocatorias, setConvocatorias] = useState(null);
  const [pagination, setPagination] = useState();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const isCurrentLastPage = pagination?.page === pagination?.pages;
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredConvocatorias, setFilteredConvocatorias] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await convocatoriaController.getConvocatorias(page, 30);
        setConvocatorias(response.docs);
        setPagination({
          page: response.page,
          pages: response.pages,
        });      
        if (!convocatorias) setConvocatorias(response.docs);
        else setConvocatorias([...convocatorias, ...response.docs]);
      } catch  {
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadMore = () => {
    setPage((prevState) => prevState + 1);
  };


  useEffect(() => {
    if (convocatorias) {
      const filtered = convocatorias.filter(convocatoria => {    
        for (const key in convocatoria) {
          if (typeof convocatoria[key] === 'string' &&
              convocatoria[key].toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
      setFilteredConvocatorias(filtered);    }
  }, [searchTerm, convocatorias]);
  if (!convocatorias) return <Loader active inline="centered" />;

  return (   
    <div className="list-convocatorias-web">
      <div className="title">      
        <div className="title-convocatorias">CONVOCATORIAS</div>
        <hr className="title-line"></hr>     
      </div>
      <div className="list-convocatorias-web__input">
        <Input
          className="list-convocatorias-web__input__2"
          icon="search"
          size="large"
          placeholder="Buscar por título..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="list">
        {filteredConvocatorias?.map(convocatoria=>{
          return(
            <div key={convocatoria._id} className="item">
            <ListConvocatoriasItem convocatoria={convocatoria} />
          </div>
          )
        })
        }
      </div>

      {!isCurrentLastPage && (
     <div className="more">
       <Button primary onClick={loadMore}>
         Cargar más...
       </Button>
     </div>
   )}
    </div>
  );
}
