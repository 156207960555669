import React, { useState } from "react";
import { Button,Tab } from "semantic-ui-react";
import { BasicModal } from "../../../components/Shared";
import { ListCourses, CourseForm } from "../../../components/Admin/Course";
import "./Courses.scss";

export function Courses() {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onReload = () => setReload((prevState) => !prevState);

   return (
    <>
      <div className="courses-page_admin">
        <div className="courses-page_admin__add">
          <Button primary onClick={onOpenCloseModal}>
            Nuevo curso
          </Button>
        </div>
        <div className="courses-page_admin__list">
          <ListCourses reload={reload} onReload={onReload} />
        </div>
       
      </div>

      <BasicModal show={showModal} close={onOpenCloseModal} title="Crear curso"  size="large">
        <CourseForm onClose={onOpenCloseModal} onReload={onReload} />
      </BasicModal>
    </>
  );
}
