import React,{useState,useEffect,useRef} from "react";
import { Table,Button,Icon,Confirm} from "semantic-ui-react";
import { Transaction} from "../../../../../api";
import { useAuth } from "../../../../../hooks";
import { BasicModal } from "../../../../Shared";
import QRCode from "react-qr-code";
// import * as htmlToImage from 'html-to-image';
import {DateTime} from "luxon"
import html2canvas from 'html2canvas';
import './ViewCourses.scss'

const transactionController = new Transaction();
export const ViewCourses = ({client,onReload}) => {
    const { accessToken } = useAuth();
    const rolAdmin = useAuth().user.role;
    const [transactions, setTransactions] = useState(null)
    
    const qrRef = useRef(null);
    const [titleModal, setTitleModal] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
    const [confirmMessage, setConfirmMessage] = useState("");
      const [showConfirm, setShowConfirm] = useState(false);
    const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);



    useEffect(() => {    
        (async () => {
          try {
            setTransactions(null);
            const response = await transactionController.getTransactions(
              accessToken,       
              client._id   
            );
            setTransactions(response);       
          } catch (error) {
            console.error(error);
          }
        })();     
    }, [accessToken]);
    const onDelete = async (transaction) => {   
      try {
        await transactionController.deleteTransaction(accessToken, transaction._id);
        onReload();
        onOpenCloseConfirm();
      } catch (error) {
        console.error(error);
      }
    };

    const openDeleteConfirm = (transaction) => { 
      setConfirmMessage(`Eliminar el curso ${transaction.course}`);
      onOpenCloseConfirm();
    };
 
    const openBuyCourse = (transaction) => {

  
      const downloadQRCode = () => {
        if (qrRef.current) {
          html2canvas(qrRef.current).then((canvas) => {
            const link = document.createElement('a');
            document.body.appendChild(link);
            const client = transaction.clientname.replace(/\./g, '_');
            link.download = `${client}-${transaction.course}`;
            link.href = canvas.toDataURL('image/png');
            link.click();
            document.body.removeChild(link);
          });
        }
      };
      console.log(transaction)
      
      // const url=`http://localhost:3000/validacion/${transaction._id}`
      const url=`https://risingconsultora.com/validacion/${transaction.code}`
      setTitleModal(`Qr:  ${transaction.course}`);
      onOpenCloseModal();
      setModalContent(       

      <div ref={qrRef}>     
        <h2 className="codigo"> {transaction.code}</h2>
          <QRCode
            title= {transaction._id}
            // ref={qrRef}
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={url}
            viewBox={`0 0 256 256`}
          /> 
          <Button style={{float: "right",margin:"10px 0px 15px 0px"}} icon color="blue"  onClick={downloadQRCode}>
            Descargar 
              <Icon name="download" />
          </Button>   
      </div>
      );
    };
   
    return (
      <>
      {transactions?.length!==0?
     
      <Table celled compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ width: '5%' }}>Nº</Table.HeaderCell>
          <Table.HeaderCell style={{ width: '25%' }}>Curso</Table.HeaderCell>    
          <Table.HeaderCell style={{ width: '25%' }}>Fecha</Table.HeaderCell>         
          <Table.HeaderCell style={{ width: '20%' }}>Pedido Finalizado</Table.HeaderCell>
          <Table.HeaderCell style={{ width: '20%' }}>Qr</Table.HeaderCell>
          {rolAdmin==='admin'?
            <Table.HeaderCell style={{ width: '20%' }}>Eliminar</Table.HeaderCell>
          :
          <></>
        }
        </Table.Row>
      </Table.Header>
      <Table.Body>

      {transactions?.map((transaction, index) => {     
        const fechaAux = DateTime.fromISO(transaction.date).toLocal();
        const fecha = fechaAux.toLocaleString({ 
          year: 'numeric', 
          month: '2-digit', 
          day: '2-digit' 
        });
        return(
          <Table.Row key={transaction._id}>
          <Table.Cell>{index+1}</Table.Cell>
          <Table.Cell>{transaction.course}</Table.Cell>
          <Table.Cell>{fecha}</Table.Cell>
          <Table.Cell textAlign='center'>
              {transaction.state===false?
                <Button  icon color="red">
                Pendiente
              </Button> 
              :
              <Button  icon color="green">
                  Enviado
                </Button> 
              }    
          </Table.Cell>
          <Table.Cell  textAlign='center'>
            <Button  icon color="blue" 
            onClick={() => openBuyCourse(transaction)}
            >
              <Icon name="qrcode" />
            </Button>         
          </Table.Cell>
          {rolAdmin==='admin'?
          <>
           <Table.Cell  textAlign='center'>
              <Button  icon color="red" 
              onClick={() => openDeleteConfirm(transaction)}
              >
                 <Icon name="trash" />
              </Button>         
            </Table.Cell>
            <Confirm
              open={showConfirm}
              onCancel={onOpenCloseConfirm}
              onConfirm={()=>{onDelete(transaction)}}
              content={confirmMessage}
              size="mini"
            />
          </>
          :
          <></>
          }
        </Table.Row>
        )
      })}
           </Table.Body>   
           <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>       
        {modalContent}
      </BasicModal>

      
    </Table>
    : <h1>El cliente no tiene cursos comprados</h1>
    
      }
     
      </>

     
    );
}


