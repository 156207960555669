import React from "react";
import { Container } from "semantic-ui-react";
import { Link,useLocation } from "react-router-dom";
import { Icon } from "../../../assets";


import "./TopBar.scss";

  

// const menuController = new Menu();
export function TopBar() {
  const location = useLocation();
  const isHome = location.pathname === '/';
  return (
    <div className="top-bar">
      <Container>
          <Link to="/" className="logo">
          {isHome ? <Icon.LogoRising /> : <Icon.LogoWhite />}
          </Link>
          
          <div className="menu">           
            <Link to="/" className="link">             
              Inicio      
            </Link>         
            <Link to="/cursos" className="link">
              Cursos 
            </Link>
            {/* <Link to="/convocatorias" className="link">
              Convocatorias
            </Link> */}
            <Link to="/nosotros" className="link">
              Nosotros 
            </Link>
            <Link to="/validacion" className="link">
              Verificacion
            </Link>         
          </div>
      </Container>
    
    </div>
  );
}
