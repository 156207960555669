import React, { useState, useEffect } from "react";
import { map } from "lodash";
import { Client } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { ClientItem } from "../ClientItem";
import { Table, Input, Pagination } from 'semantic-ui-react';

const userController = new Client();
const ITEMS_PER_PAGE = 10;

export function ListClients(props) {
  const { reload, onReload } = props;
  const [clients, setClients] = useState(null);
  const { accessToken } = useAuth();
  const [filtrarPor, setFiltrarPor] = useState('');
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    (async () => {
      try {
        setClients(null);
        const response = await userController.getClients(accessToken);
        setClients(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [reload, accessToken]);

  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const filtrarDatos = clients?.filter((dato) => {
    const valores = Object.values(dato);
    return valores.some((valor) =>
      typeof valor === "string" && valor.toLowerCase().includes(filtrarPor.toLowerCase())
    );
  });

  const paginatedData = filtrarDatos ? chunkData(filtrarDatos, ITEMS_PER_PAGE) : [];

  const currentPageData = paginatedData[activePage - 1] || [];

  return (
    <div>
      <Input
        placeholder="Buscar..."
        value={filtrarPor}
        onChange={(e) => setFiltrarPor(e.target.value)}
      />
      <Table celled compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: '5%' }}>Nº</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '25%' }}>Nombre</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '10%' }}>Carnet</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }}>Celular</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }}>Grado</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }}>Cursos</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }}>Acciones</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {map(currentPageData, (client, index) => (
            <ClientItem key={client._id} client={client} onReload={onReload} index={(activePage - 1) * ITEMS_PER_PAGE + index + 1} />
          ))}
        </Table.Body>
      </Table>
      <Pagination
        activePage={activePage}
        onPageChange={handlePaginationChange}
        totalPages={paginatedData.length}
      />
    </div>
  );
}

function chunkData(data, size) {
  const chunks = [];
  for (let i = 0; i < data.length; i += size) {
    chunks.push(data.slice(i, i + size));
  }
  return chunks;
}
