import React from "react";
import { useLocation,Routes, Route  } from "react-router-dom";
import {useAuth} from "../../../hooks"
import { ReportClient,ReportCourse,ReportDate,ReportSeller } from "../../../components/Admin/Reports";
import "./Report.scss"

export function Report() {
  const { accessToken } = useAuth();
  const location = useLocation().pathname?.split('/')[2];




  const reportComponent = (() => {
    switch (location) {
      case "reportefecha":
        return <ReportDate accessToken={accessToken}/>;
      case "reportevendedor":
        return <ReportSeller accessToken={accessToken} />;
      case "reportecliente":
        return <ReportClient accessToken={accessToken}/>;
      case "reportecurso":
        return <ReportCourse accessToken={accessToken}/>;
      default:
        return null;
    }
  })();
   return(
    <>
    {reportComponent}  
    </>
   )

  

 
  

}
