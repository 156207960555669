import React from "react";
import { Container } from "semantic-ui-react";
import { useParams } from "react-router-dom";

import "./ValidateQr.scss"
import { ValidateQr as Validate} from "../../../components/Web";

export function ValidateQr() {
  const { path } = useParams();
  return (
    <div className="container__qr">     
      <Container >     
        <Validate path={path}/>  
      </Container>     
    </div>
  );
}
