import React from 'react'
import { Button,Select,Table,Loader } from "semantic-ui-react";
import { useReport } from '../../../../hooks'
import * as XLSX from "xlsx";
import "./ListReports.scss"


//:id
export const ReportCourse = ({accessToken}) => {
 const {selectData,selectedData,handleChange,tableData,handleviewTable,
  load,setgenerateReport,dataName}=useReport(accessToken,'coursesforreport','corsestransactions') 

  const handleGenerateExcel = () => {     
    const totalPrecio = tableData.reduce((total, producto) => total + producto.precio, 0);
    setgenerateReport(true)
    const filaAdicional = [
      ['Nombre de curso',`${dataName.text}`],
      ['Total generado',`${totalPrecio} Bs`] ,
      [''] 
    ];
    const ws = XLSX.utils.json_to_sheet(tableData);
    const excelData = XLSX.utils.sheet_to_json(ws, { header: 1 });     
    const wsMerged = XLSX.utils.aoa_to_sheet(filaAdicional.concat(excelData));
    wsMerged['!cols'] = [
      { wch: 20 }, 
      { wch: 20 }, 
      { wch: 12}, 
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wsMerged, "Hoja 1");
    XLSX.writeFile(wb,`${dataName.text}.xlsx` );
};
  return (
    <>
     <div className='report'>
        <div className='report__left'>
          <span className='report__left-span'>Seleccionar curso</span>
          <Select
            placeholder='Seleccionar curso' 
            options={selectData}
            value={selectedData}
            onChange={handleChange}
            />
        </div>
        <div >
        {tableData.length!==0?
        <Button  primary onClick={handleGenerateExcel}>
          Imprimir reporte
          </Button>  
          :
          <></>
        }
        <Button primary onClick={handleviewTable}>
          Generar reporte 
          </Button>  
        </div>
     </div>     

    {load===false?
      <div className="loadingtable">
        <div className='loadingtable-loader'>
            <Loader active inline='centered' size='massive' >
                Cargando...
            </Loader>
        </div>
      </div>
    :
    <>
       {tableData.length===0?
        <></>
        :
        <>
          <Table celled compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: '5%' }}>Nº</Table.HeaderCell>            
                <Table.HeaderCell style={{ width: '15%' }}>Cliente</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '15%' }}>Agente</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '15%' }}>Fecha de venta</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '15%' }}>Precio de venta</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {tableData?.map((transaction,index)=>{
                return(
                  <Table.Row key={index}>
                    <Table.Cell>{index}</Table.Cell>
                    <Table.Cell>{transaction.cliente}</Table.Cell>
                    <Table.Cell>{transaction.agente}</Table.Cell>
                    <Table.Cell>{transaction.fecha}</Table.Cell>
                    <Table.Cell>{transaction.precio}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </> 
       }
      </>
     }  
    </>
  )
}
