import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks";
import "./Logout.scss"

export function Logout() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const {name,role}= useAuth()?.user;  
  const user= name?.split(' ').map(nombre => nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase()).join(' ');
  const rol = role === 'admin' ? 'Admin' : 'Agente';  
  const onLogout = () => {
    logout();
    navigate("/admin");
  };

  return (
    <>
    <div className="user">{rol}: {user}</div>
     <Button icon basic color="red" onClick={onLogout}>
      <Icon name="power off" /> Cerrar sesión
    </Button>
    </>
   
  );
}
