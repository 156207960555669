import * as Yup from "yup";

export function initialValues(course) {
  return {
    title: course?.title || "",
    price: course?.price || "",
    miniature: course?.miniature || "",
    workload: course?.workload || "",
    // urlimage: course?.urlimage || "",
  };
}


export function validationSchema() {
  return Yup.object({
    miniature: Yup.string().required(true),
    title: Yup.string().required(true),
    price: Yup.number().required(true),
    workload: Yup.number().required(true),
    // urlimage: Yup.string().required(true),
  
    // score: Yup.number().min(1, true).max(5, true).required(true),
  });
}
