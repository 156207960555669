import React,{useState,useEffect} from "react";
import { Form } from "semantic-ui-react";
import { useFormik } from "formik";
import { Transaction,Course } from "../../../../../api";
import { useAuth } from "../../../../../hooks";
import { initialValues } from "./BuyForm.form";
import "./BuyCourse.scss";

const transactionController = new Transaction();
const courseController=new Course();

export function BuyCourse({ close, onReload,client}) {  
  const { accessToken,user } = useAuth();

  const [courses, setCourses] = useState([])
  const [valueCourses,setValueCourses] = useState([])
 
  useEffect(() => {
    (async () => {
      try {
        setCourses(null);
        const response = await courseController.getAllcourses(
          accessToken,          
        );
        setCourses(response);        
      } catch (error) {
        console.error(error);
      }
    })();    
  }, [accessToken]);

  useEffect(() => {
    if(courses!=null){
      setValueCourses(courses?.map((course,index)=>{
        return {
          key: index+1,
          text: course?.title,
          value: course?._id,
          price: course?.price
        };
      }))      
    }  
  }, [courses])

  const formik = useFormik({
    initialValues: initialValues(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {       
        await transactionController.createTransaction(accessToken,user._id,client._id, formValue);
        onReload();
        close();
      } catch (error) {       
        onReload();
        close();
      }
    },
  });

  return (
    <Form className="user-form" onSubmit={formik.handleSubmit}> 
           <Form.Group widths={2}>
            <Form.Dropdown
                search
                fluid
                label="Cursos"
                placeholder="Seleccióna un curso"
                options={valueCourses?.map((course) => ({
                    key: course.value,
                    text: `${course.text} - ${course.price} Bs`,
                    value: course.value,
                  }))}
                selection
                multiple
                onChange={(e, data) => {
                    const selectedCourses = data.value.map((selectedValue) => {
                    const selectedCourse = valueCourses.find((course) => course.value === selectedValue);
                    return {
                        title: selectedValue,
                        price: selectedCourse ? selectedCourse.price : 0, 
                    };
                    });
                    formik.setFieldValue('courses', selectedCourses); 
                }}
                value={Array.isArray(formik.values.courses) ? formik.values.courses.map((course) => course.title) : []}
                error={formik.errors.courses}
            />
             <Form.Group widths={2}>
             <Form.Input
                readOnly
                fluid
                label="Precio"
                name="price"
                value={
                    Array.isArray(formik.values.courses)
                    ? formik.values.courses.reduce((totalPrice, course) => totalPrice + course.price, 0)+" Bs"
                    : 0+" Bs"
                }
                error={formik.errors.price}
                />
            </Form.Group>
        </Form.Group> 
      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        Registrar compra
      </Form.Button>
    </Form>
  );
}