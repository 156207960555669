import React,{useState} from "react";
import { Form,Icon } from "semantic-ui-react";
import { useFormik } from "formik";
import { Auth } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { inititalValues, validationSchema } from "./LoginForm.form";
import "./LoginForm.scss"

const authController = new Auth();

export function LoginForm() {
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: inititalValues(),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        const response = await authController.login(formValue);

        authController.setAccessToken(response.access);
        authController.setRefreshToken(response.refresh);

        login(response.access);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login">
      <div className="title">Autenticacion</div>
        <Form onSubmit={formik.handleSubmit}>     
          <Form.Input
            name="username"
            placeholder="Nombre de Usuario"
            onChange={formik.handleChange}
            value={formik.values.username}
            error={formik.errors.username}
          />
          {/* <Form.Input
            name="password"
            type="password"
            placeholder="Contraseña"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.errors.password}
          /> */}

        <Form.Input
          name="password"
          type={showPassword ? "text" : "password"}
          placeholder="Contraseña"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.errors.password}
          icon={
            <Icon
              name={showPassword ? "eye slash" : "eye"}
              link
              onClick={togglePasswordVisibility}
            />
          }
        />

          <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
            Entrar
          </Form.Button>
        </Form>

    </div>
    
    
  );
}
