import * as Yup from "yup";

export function initialValues(user) {
  return {
    name: user?.name || "",
    ci: user?.ci || "", 
    phone: user?.phone || "",   
    grade: user?.grade || "", 
    city: user?.city || "", 
  };
}

export function validationSchema() {
  return Yup.object({
    name: Yup.string().required(true),
    ci: Yup.string().required(true),
    phone: Yup.string().required(true),   
    grade: Yup.string().required(true),    
    city: Yup.string().required(true),  
  });
}
