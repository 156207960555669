import { ENV } from "../utils";

export class Convocatoria {
  baseApi = ENV.BASE_API;

  async createConvocatoria(accessToken, data) {
    try {      
      const url = `${this.baseApi}/${ENV.API_ROUTES.CONVOCATORIA}`;
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-type':'application/json'
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(url, params);
      const result = await response.json();
      if (response.status !== 201) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getConvocatorias(page = 1, limit = 10) {
    try {
      const pageFilter = `page=${page}`;
      const limitFilter = `limit=${limit}`;
      const url = `${this.baseApi}/${ENV.API_ROUTES.CONVOCATORIA}?${pageFilter}&${limitFilter}`;
      const response = await fetch(url);
      const result = await response.json();
      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async updateConvocatoria(accessToken, idPost, data) {
    try {    
      const url = `${this.baseApi}/${ENV.API_ROUTES.CONVOCATORIA}/${idPost}`;
      const params = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-type':'application/json'
        },
        body: JSON.stringify(data)
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async deleteConvocatoria(accessToken, idPost) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.CONVOCATORIA}/${idPost}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async getConvocatoria(path) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.CONVOCATORIA}/${path}`;

      const response = await fetch(url);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async deletePrevConvocatorias(accessToken, fecha) {
    try {
      const url = `${this.baseApi}/api/deletePrevConvocatorias/${fecha}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

}
