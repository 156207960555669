import React,{useEffect,useState} from 'react'
import { Form, } from "semantic-ui-react";
import { FormData} from '.'
import { useAuth } from "../../../../../hooks";
import { Course } from "../../../../../api";
const courseController=new Course();
export const NewClientFrom = ({formik,user,gradeOptions,cityOptions, handleAddCity,handleAddGrade}) => {
    const [courses, setCourses] = useState(null);
    const [valueCourses,setValueCourses] = useState([])
    const { accessToken } = useAuth();
    
 useEffect(() => {
    (async () => {
      try {
        setCourses(null);
        const response = await courseController.getAllcourses(
          accessToken,          
        );
        setCourses(response);        
      } catch (error) {
        console.error(error);
      }
    })();    
  }, [accessToken]);

  useEffect(() => {
    if(courses!=null){  
      setValueCourses(courses?.map((course,index)=>{
        return {
          key: index+1,
          text: course?.title,
          value: course?._id,
          price: course?.price
        };
      }))      
    }  
  }, [courses])


  return (
    <Form className="user-form" onSubmit={formik.handleSubmit}> 
        {/* <h3> Datos del cliente</h3> */}
        <FormData formik={formik} user={user} gradeOptions={gradeOptions} cityOptions={cityOptions} handleAddCity={handleAddCity} handleAddGrade={handleAddGrade}  />
        <hr/>
        <h3> Cursos</h3>
        <Form.Group widths={2}>
            <Form.Dropdown
                search
                fluid
                label="Cursos"
                placeholder="Seleccióna un curso"
                options={valueCourses.map((course) => ({
                    key: course.value,
                    text: `${course.text} - ${course.price} Bs`,
                    value: course.value,
                  }))}
                selection
                multiple
                onChange={(e, data) => {
                    const selectedCourses = data.value.map((selectedValue) => {
                    const selectedCourse = valueCourses.find((course) => course.value === selectedValue);
                    console.log(selectedCourse)
                    return {
                        course_id: selectedValue,
                        price: selectedCourse ? selectedCourse.price : 0, 
                    };
                    });
                    formik.setFieldValue('courses', selectedCourses); 
                }}
                value={Array.isArray(formik.values.courses) ? formik.values.courses.map((course) => course.course_id) : []}
                error={formik.errors.courses}
            />
             <Form.Group widths={2}>
             <Form.Input
                readOnly
                fluid
                label="Precio"
                name="price"
                value={
                    Array.isArray(formik.values.courses)
                    ? formik.values.courses.reduce((totalPrice, course) => totalPrice + course.price, 0)+" Bs"
                    : 0+" Bs"
                }
                error={formik.errors.price}
                />
            </Form.Group>
        </Form.Group>
        <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
            Crear cliente
        </Form.Button>
    </Form>
  )
}
