import React, { useState } from "react";
import { Button, Icon, Confirm,Table } from "semantic-ui-react";
import { useAuth } from "../../../../hooks";
import {DateTime} from "luxon"
import "./SendItem.scss";

export function SendItem({transaction,transactionController,onReload}) {
  const { accessToken } = useAuth();
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);
  
  const fechaAux = DateTime.fromISO(transaction.date).toLocal();
  const fecha = fechaAux.toLocaleString({ 
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit' 
  });
  
  const onSend = async () => {   
    try {
      await transactionController.transactionUpdate(accessToken, transaction._id);
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };
  const openSendConfirm = () => { 
    setConfirmMessage(`Desea enviar el certificado?`);
    onOpenCloseConfirm();
  };

  return(
    <>
      <Table.Row key={transaction._id}>
        <Table.Cell>{transaction.clientname}</Table.Cell>
        <Table.Cell>{transaction.course}</Table.Cell>
        <Table.Cell>{fecha}</Table.Cell>       
        <Table.Cell  textAlign='center'>         
          <Button  icon color='blue' onClick={() => openSendConfirm()} >
            <Icon name="envelope outline" />
          </Button>
        </Table.Cell>        
      </Table.Row>
      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={ onSend }
        content={confirmMessage}
        size="mini"
      />
    </>   
  )
}