import React, { useState, useEffect } from "react";
import { Transaction } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { SendItem } from "../SendItem";
import { Table, Input, Pagination ,Loader } from 'semantic-ui-react';
// 
const TransactionController = new Transaction();
const ITEMS_PER_PAGE = 10;

export function ListSends() {

  const [transactions, setTransactions] = useState(null);
  const {accessToken } = useAuth();  
  const [reload, setReload] = useState(false);
  const onReload = () => setReload((prevState) => !prevState);
  const [filtrarPor, setFiltrarPor] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [load, setLoad] = useState(false)

  useEffect(() => {
 
    (async () => {
      setLoad(false)
      try {
        setTransactions(null);
        const response = await TransactionController.getTransactionsSend(accessToken);
        setTransactions(response);
        setLoad(true)
      } catch (error) {
        console.error(error);
      }
    })(); 
  }, [reload, accessToken]);
console.log(transactions)
  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const filtrarDatos = transactions?.filter((dato) => {
    const valores = Object.values(dato);
    return valores.some((valor) =>
      typeof valor === "string" && valor.toLowerCase().includes(filtrarPor.toLowerCase())
    );
  });

  const paginatedData = filtrarDatos ? chunkData(filtrarDatos, ITEMS_PER_PAGE) : [];
  const currentPageData = paginatedData[activePage - 1] || [];  
  return (
    <>
    {load===true?
     <>
     {currentPageData.length!==0?
     <>
      <Input
         placeholder="Buscar..."
         value={filtrarPor}
         onChange={(e) => setFiltrarPor(e.target.value)}
       />
       <Table celled compact>
         <Table.Header>
           <Table.Row>
             {/* <Table.HeaderCell style={{ width: '5%' }}>Nº</Table.HeaderCell> */}
             <Table.HeaderCell style={{ width: '25%' }}>Cliente</Table.HeaderCell>
             <Table.HeaderCell style={{ width: '25%' }}>Curso</Table.HeaderCell>
             <Table.HeaderCell style={{ width: '15%' }}>Fecha de compra</Table.HeaderCell>          
             <Table.HeaderCell style={{ width: '20%' }}>Enviar</Table.HeaderCell>
           </Table.Row>
         </Table.Header>
         <Table.Body>
           {currentPageData?.map(transaction=>{
             return(
               <SendItem key={transaction._id} transaction={transaction} transactionController={TransactionController} onReload={onReload} />
             )
           })}
         </Table.Body>
       </Table>
       <Pagination
         activePage={activePage}
         onPageChange={handlePaginationChange}
         totalPages={paginatedData.length}
       />
     </>
     :
     <>
       <h1>No tiene envios pendientes</h1>
     </>
     }
     </>
     :
     <div className="loadingtable">
        <div className='loadingtable-loader'>
            <Loader active inline='centered' size='massive' >
                Cargando...
            </Loader>
        </div>
      </div>

    }
    </>
   
  );
}

function chunkData(data, size) {
  const chunks = [];
  for (let i = 0; i < data.length; i += size) {
    chunks.push(data.slice(i, i + size));
  }
  return chunks;
}
