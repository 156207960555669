import React from "react";
import { Form, Image,Message } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import { Course } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { ENV } from "../../../../utils";
import { initialValues, validationSchema } from "./CourseForm.form";
import "./CourseForm.scss";

const courseController = new Course();

export function CourseForm(props) {
  const { onClose, onReload, course } = props;
  const { accessToken } = useAuth();
  const formik = useFormik({
    initialValues: initialValues(course),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      console.log(formValue)
      try {
        if (!course) {
          await courseController.createCourse(accessToken, formValue);
        } else {
          await courseController.updateCourse(accessToken,course._id,formValue);
        }
        onReload();
        onClose();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const onDrop = acceptedFiles => {
    
    const file = acceptedFiles[0];      
    if (file?.size <= 1024 * 3000) {    
      const pathAux= file.path.split('.')





      const path=pathAux[pathAux.length -1]
      const extensions =[
        'jpeg','png','jpg','JPG','PNG','JPEG'
      ]   
      if (extensions.includes(path)) {



        formik.setFieldValue('miniature', URL.createObjectURL(file));
        formik.setFieldValue('file', file);
        formik.setFieldError('file', '');



      } else {
        formik.setFieldValue('file', null);
        formik.setFieldError('file', 'El archivo no es una imagen');
      }


      
    } 
    else {
      formik.setFieldValue('file', null);
      formik.setFieldError('file', 'Imagen demasiado grande. tamaño maximo 200 KB');
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 1024 * 3000,   
    onDrop,
  });
  const getMiniature = () => {
    const { file, miniature } = formik.values;  
    if (file) {     
      return URL.createObjectURL(file);
    } else if (miniature) {    
      return `${ENV.BASE_PATH}/${miniature}`;
    }
    return null;
  };

  return (
    <Form className="course-form" onSubmit={formik.handleSubmit}>  
      <div className="course-form__miniature" {...getRootProps()}>
      {getMiniature() ? 
      <>
        <input {...getInputProps()} accept=".jpeg, .jpg, .png, .JPEG, .JPG, .PNG" />
        <Image size="medium" src={getMiniature()} onError={() => formik.setFieldValue('file', null)} />
      </>       
       : 
       <div className="course-form__miniature__margen">
        <div  {...getRootProps({ className: 'dropzone' })}>
          <div></div>
          <input {...getInputProps()} accept=".jpeg, .jpg, .png"/>
          <p className="course-form__miniature__margen__p">Arrastra y suelta archivos de imagen aquí, o haz clic para seleccionar archivos</p>
          <p className="course-form__miniature__margen__p">Exteniciones aceptadas: JPEG, PNG </p>
          {formik.errors.file && <Message negative>{formik.errors.file}</Message>}
        </div>
       </div>
      }
    </div>
      <Form.Input
        label="Curso"
        name="title"
        placeholder="Nombre del curso"
        onChange={formik.handleChange}
        value={formik.values.title}
        error={formik.errors.title}
      />
       <Form.Group widths={2}>
          <Form.Input
            label="Precio"
            type="Number"
            name="price"
            placeholder="Precio"
            onChange={formik.handleChange}
            value={formik.values.price}
            error={formik.errors.price}
          />
          <Form.Input
            label="Carga horaria"
            type="Number"
            name="workload"
            placeholder="Carga horaria"
            onChange={formik.handleChange}
            value={formik.values.workload}
            error={formik.errors.workload}
          />
        </Form.Group>
        {/* <Form.TextArea 
          label="Url de la imagen"        
          name="urlimage"
          placeholder="Copiar la Url de la imagen"
          onChange={formik.handleChange}
          value={formik.values.urlimage}
          error={formik.errors.urlimage}
        /> */}
        {/* <Form.Input
          type="number"
          name="score"
          placeholder="Puntuacion del curso"
          onChange={formik.handleChange}
          value={formik.values.score}
          error={formik.errors.score}
        /> */}


      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        {!course ? "Crear curso" : "Actualizar curso"}
      </Form.Button>
    </Form>
  );
}
