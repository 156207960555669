import React from "react";
import { Container } from "semantic-ui-react";
import {
  Banner,
  HomeCourses,
  AboutUsMenu
  
 
} from "../../../components/Web";
import "./us.scss"
export function Us() {
  return (
    <div className="container__us">
      <Container >
        <AboutUsMenu/>  
      </Container>     
    </div>
  );
}
