import React, { useState,useEffect } from 'react'
import { Button,Input,Table,Loader, } from "semantic-ui-react";
import { useReport } from '../../../../hooks'
import * as XLSX from "xlsx";
import {Report } from "../../../../api"
const reportController = new Report();
export const ReportDate = ({accessToken}) => {
  
  const [generateReport, setgenerateReport] = useState(false)
  const [changeValue, setChangeValue] = useState(false)
  const [tableData, setTableData] = useState([])
  const [selectedData, setSelectedData] = useState('');
  const [dataName, setDataName] = useState('')
  const [load, setLoad] = useState(true)
  const [dates, setDates] = useState({
    dateStart:'',
    dateEnd:''
  })  
  const today = new Date().toISOString().split('T')[0];
  
  useEffect(() => {
    setgenerateReport(false)   
      if(changeValue===true){
        (async () => {
          setChangeValue(false)
          setTableData([])
          setLoad(false)
          try { 
              const response = await reportController.getDataDate(accessToken,dates);
              // const response = await reportController.getDataTable(accessToken,dates,'datetransactions');
              console.log(response)
              setTableData(response);
              setLoad(true)
          } catch (error) {
            console.error(error);
          }
        })();
      }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateReport]); 

  

    const handleGenerateExcel = () => {     
      const totalPrecio = tableData.reduce((total, producto) => total + producto.precio, 0);
      setgenerateReport(true)
      const filaAdicional = [
        ['rango de fechas: ',`${dates.dateStart}-${dates.dateEnd}`],
        ['Total Comprado',`${totalPrecio} Bs`] ,
        [''] 
      ];
      const ws = XLSX.utils.json_to_sheet(tableData);
      const excelData = XLSX.utils.sheet_to_json(ws, { header: 1 });     
      const wsMerged = XLSX.utils.aoa_to_sheet(filaAdicional.concat(excelData));
      wsMerged['!cols'] = [
        { wch: 40 }, 
        { wch: 15 }, 
        { wch: 20 }, 
      ];
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, wsMerged, "Hoja 1");
      XLSX.writeFile(wb,`${dates.dateStart} _ ${dates.dateEnd}.xlsx` );
  };

  const handleviewTable=()=>{
  setgenerateReport(true)
  }
  const handleInputStart=(e)=>{
    setChangeValue(true)
    setDates({dateEnd:e.target.value,dateStart:e.target.value})
  }
  const handleInputEnd=(e)=>{   
    setDates({...dates,dateEnd:e.target.value})
  }
  return (
    <>
    <div className='report'>
       <div className='report__left'>
         <span className='report__left-span'>Rango de fechas</span>
         <Input
          type='date'
          onChange={handleInputStart}
          // value={dateStart}
          value={dates.dateStart}
          max={today}
         />
            <span className='report__left-span'> a </span>
         <Input    
          type='date'
          onChange={handleInputEnd}
          value={dates.dateEnd}
          // value={dateEnd}
          max={today}
          min={dates.dateStart}
           />
       </div>
       <div >
       {tableData.length!==0?
       <Button  primary onClick={handleGenerateExcel}>
         Imprimir reporte
         </Button>  
         :
         <></>
       }
       <Button primary onClick={handleviewTable}>
         Generar reporte 
         </Button>  
       </div>
    </div>     

   {load===false?
     <div className="loadingtable">
       <div className='loadingtable-loader'>
           <Loader active inline='centered' size='massive' >
               Cargando...
           </Loader>
       </div>
     </div>
   :
   <>
      {tableData.length===0?
       <></>
       :
       <>
         <Table celled compact>
           <Table.Header>
             <Table.Row>
               <Table.HeaderCell style={{ width: '5%' }}>Nº</Table.HeaderCell>            
               <Table.HeaderCell style={{ width: '15%' }}>Cliente</Table.HeaderCell>
               <Table.HeaderCell style={{ width: '15%' }}>Curso</Table.HeaderCell>
               <Table.HeaderCell style={{ width: '15%' }}>Agente</Table.HeaderCell>
               <Table.HeaderCell style={{ width: '15%' }}>Fecha</Table.HeaderCell>
               <Table.HeaderCell style={{ width: '15%' }}>Precio</Table.HeaderCell>
             </Table.Row>
           </Table.Header>
           <Table.Body>
             {tableData?.map((transaction,index)=>{
               return(
                 <Table.Row key={index}>
                   <Table.Cell>{index+1}</Table.Cell>
                   <Table.Cell>{transaction.cliente}</Table.Cell>
                   <Table.Cell>{transaction.curso}</Table.Cell>
                   <Table.Cell>{transaction.vendedor}</Table.Cell>
                   <Table.Cell>{transaction.fecha}</Table.Cell>
                   <Table.Cell>{transaction.precio}</Table.Cell>
                 </Table.Row>
               )
             })}
           </Table.Body>
         </Table>
       </> 
      }
     </>
    }  
   </>
    
  )
}
