import React, { useState } from "react";
import { Form ,Message,Icon} from "semantic-ui-react";
import { useFormik } from "formik";
import { User } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { initialValues, validationSchema } from "./UserForm.form";
import "./UserForm.scss";

const userController = new User();

export function UserForm(props) {
  const { close, onReload, user } = props;
  const { accessToken } = useAuth();
  const [error, setError] = useState(null)
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: initialValues(user),
    validationSchema: validationSchema(user),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (!user) {
          await userController.createUser(accessToken, formValue);
        } else {
          await userController.updateUser(accessToken, user._id, formValue);
        }
        onReload();
        close();
      } catch (error) {
        setError(error.msg)
      }
    },
  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Form className="user-form" onSubmit={formik.handleSubmit}>     
        <Form.Input
          fluid label="Nombre"
          name="name"
          placeholder="Nombre"
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.errors.name}
        />
        <Form.Input
          fluid label="Nombre de usuario"
          name="username"
          placeholder="Nombre de Usuario"
          onChange={formik.handleChange}
          value={formik.values.username}
          error={formik.errors.username}
        />
     
        <Form.Input
          name="password"
          type={showPassword ? "text" : "password"}
          placeholder="Contraseña"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.errors.password}
          icon={
            <Icon
              name={showPassword ? "eye slash" : "eye"}
              link
              onClick={togglePasswordVisibility}
            />
          }
        />
        <Form.Dropdown
          fluid label="Rol"
          placeholder="Seleccióna un rol"
          options={roleOptions}
          selection
          onChange={(_, data) => formik.setFieldValue("role", data.value)}
          value={formik.values.role}
          error={formik.errors.role}
        />
      
        <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
          {user ? "Actualizar usuario" : "Crear usuario"}
        </Form.Button>
      </Form>
    
    {error && (
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{error}</p>
        </Message>
      )}
    </>
   
  );
}

const roleOptions = [
  {
    key: "user",
    text: "Usuario",
    value: "user",
  },
  {
    key: "admin",
    text: "Administrador",
    value: "admin",
  },
];
