import React from "react";
import { LoginForm } from "../../../components/Admin/Auth";
import { Icon } from "../../../assets";
import "./Auth.scss";

export function Auth() {
  return (
    <div className="auth">
      <Icon.LogoWhite className="logo" />  
      <LoginForm />    
    </div>
  );
}
