import React from "react";
import { Image, Button, Card, Icon,Segment,Grid } from "semantic-ui-react";
import "./AboutUsMenu.scss";
import { image } from "../../../../assets";
export function AboutUsMenu() {  
  return (
    <div className="aboutusmenu">
      <div className="segment-content">
        <Segment basic >
          <Grid columns={2} stackable textAlign='center' >  
                     
                <Grid.Column  textAlign='center'>
                  <div className="segment-content__who">
                    <p className="segment-content__who__who">¿Quiénes somos?</p>
                  </div>
               
                </Grid.Column>
                <Grid.Column  textAlign='center'>
                  <p className="segment-content__font">Somos una empresa especializada en la capacitación y actualización para profesionales y estudiantes en diferentes áreas de especialización, nos esforzamos por mantener altos estándares éticos en todo lo que hacemos, desde la elaboración de contenido hasta la interacción con nuestros alumnos.</p>
                  {/* <p className="segment-content__font"> DESDE LA ELABORACION DEL CONTENIDO HASTA LA INTERACCION CON NUESTRO ALUMNOS.</p> */}
                </Grid.Column>
          </Grid>
        </Segment>
      </div>
      <div>
      <Card.Group centered>      
        <Card className="about-content">
          <Card.Content >
          <Image src={image.mision}/>
            <Card.Header>
              <strong className="about-content__font">Misión</strong>
            </Card.Header>     
            <Card.Description>
            <p className="about-content__font">Promover la formación y capacitación que asegure el buen desempeño de los profesionales y estudiantes en el cumplimiento de sus funciones.</p>
            </Card.Description>
          </Card.Content>
        </Card>    
        <Card className="about-white">
          <Card.Content>
          <Image src={image.vision}/>
            <Card.Header>
              <div className="about-content__black">
                <p>Visión</p>
              </div>              
            </Card.Header>     
            <Card.Description>
              <p className="about-content__black">Queremos ser un referente nacional en la formación de habilidades y competencias basadas en la excelencia.</p>
            </Card.Description>
          </Card.Content>
        </Card>
        <Card className="about-content">
          <Card.Content  >
          <Image src={image.valores}/>
            <Card.Header>
              <p className="about-content__font">Valores</p>
            </Card.Header>     
            <Card.Description>
              <li className="about-content__font">
              <strong className="about-content__font">Integridad </strong>
              </li>
              <li className="about-content__font">
              <strong className="about-content__font">Compromiso  </strong>
              </li>
              <li className="about-content__font">
              <strong className="about-content__font">Innovación </strong>
              </li>
            </Card.Description>
          </Card.Content>
        </Card>
      </Card.Group>
      </div>
    </div>  
  )
}
